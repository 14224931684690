/** Static map information for pre-defined regions. */

export const mapInfo = {
    "default": {
        "map_origin": [596189.85, 4141170.22],
        "map_utm_zone": [10, "S"]
    },
    "auro-site-milpitas": {
        "map_origin": [596189.85, 4141170.22],
        "map_utm_zone": [10, "S"]
    },
    "auro-site-berkeley": {
        "map_origin": [561721.63, 4190689.18],
        "map_utm_zone": [10, "S"]
    }
};
