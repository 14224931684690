import "./Signup.css";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import LoaderButton from "../../components/LoaderButton";
import { useFormFields } from "../../libs/hooks-lib";
import {
  selectIsAuthenticating,
  selectVerificationCodeRequested,
  signUp,
} from "./authSlice";
import { Link, useHistory } from "react-router-dom";

export default function Signup() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticating = useSelector(selectIsAuthenticating);
  const verificationCodeRequested = useSelector(
    selectVerificationCodeRequested
  );

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (fields.email && verificationCodeRequested) {
      history.push(`/signup/verify?email=${encodeURIComponent(fields.email)}`);
    }
  }, [history, fields.email, verificationCodeRequested]);

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function handleSubmit(event) {
    event.preventDefault();

    const args = {
      username: fields.email,
      password: fields.password,
    };
    dispatch(signUp(args));
  }

  return (
    <Col md={8} lg={6} className="m-auto py-3">
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="email" size="lg">
              <Form.Label>Email</Form.Label>
              <Form.Control
                autoFocus
                type="email"
                value={fields.email}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <hr />
            <Form.Group controlId="password" size="lg">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                autoComplete="off"
                value={fields.password}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="confirmPassword" size="lg">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                autoComplete="off"
                value={fields.confirmPassword}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <LoaderButton
              block
              size="lg"
              type="submit"
              variant="success"
              isLoading={isAuthenticating}
              disabled={!validateForm()}
            >
              Signup
            </LoaderButton>
          </Form>
          <div className="mt-2">
            <Link to="/signup/verify">Already have a verification code?</Link>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}
