export function localDate(dateString) {
  return new Date(`${dateString}T00:00:00`);
}

export function formatDate(dateInstance) {
  var options = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  };

  return dateInstance.toLocaleDateString("en", options);
}

export function formatTime(dateInstance) {
  var options = {
    hour12: false,
  };

  return dateInstance.toLocaleTimeString("en", options);
}

export function formatInterval(seconds = 0) {
  let s = (seconds % 60).toFixed(1);
  let m = Math.floor((seconds / 60) % 60);
  let h = Math.floor(seconds / 3600);

  // handle invalid times
  if (Number.isNaN(seconds) || seconds === Infinity) {
    h = 0;
    m = 0;
    s = 0;
  }

  h = h > 0 ? `${h}h ` : "";
  m = m > 0 ? `${m}m ` : "";
  s = s > 0 ? `${s}s ` : "";

  return `${h}${m}${s}`;
}
