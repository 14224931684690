import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Loader from "../../components/Loader";
import LoaderButton from "../../components/LoaderButton";
import { useFormFields } from "../../libs/hooks-lib";
import { changePassword, selectIsAuthenticating } from "./authSlice";

export default function ChangePassword() {
  const isAuthenticating = useSelector(selectIsAuthenticating);
  const dispatch = useDispatch();
  const [fields, handleFieldChange] = useFormFields({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  function validateForm() {
    return (
      fields.oldPassword.length > 0 &&
      fields.newPassword.length > 0 &&
      fields.newPassword === fields.confirmPassword
    );
  }

  function handleSubmit(event) {
    event.preventDefault();

    const args = {
      oldPassword: fields.oldPassword,
      newPassword: fields.newPassword,
    };
    dispatch(changePassword(args));
  }

  return isAuthenticating ? (
    <Loader />
  ) : (
    <Form onSubmit={handleSubmit}>
      <Form.Group size="lg" controlId="oldPassword">
        <Form.Label>Old Password</Form.Label>
        <Form.Control
          type="password"
          value={fields.oldPassword}
          onChange={handleFieldChange}
        />
      </Form.Group>
      <hr />
      <Form.Group size="lg" controlId="newPassword">
        <Form.Label>New Password</Form.Label>
        <Form.Control
          type="password"
          value={fields.password}
          onChange={handleFieldChange}
        />
      </Form.Group>
      <Form.Group size="lg" controlId="confirmPassword">
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control
          type="password"
          value={fields.confirmPassword}
          onChange={handleFieldChange}
        />
      </Form.Group>
      <LoaderButton
        block
        type="submit"
        size="lg"
        disabled={!validateForm()}
        isLoading={isAuthenticating}
      >
        Change Password
      </LoaderButton>
    </Form>
  );
}
