import "./Signup.css";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import LoaderButton from "../../components/LoaderButton";
import { useFormFields } from "../../libs/hooks-lib";
import {
  selectIsAuthenticating,
  resetVerificationCodeRequested,
  signUpConfirm,
} from "./authSlice";
import { Link } from "react-router-dom";

export default function SignupVerify() {
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const [fields, handleFieldChange] = useFormFields({
    email: searchParams.get("email") || "",
    verificationCode: "",
  });

  const isAuthenticating = useSelector(selectIsAuthenticating);

  function validateForm() {
    return fields.email.length > 0 && fields.verificationCode.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();

    const args = {
      username: fields.email,
      code: fields.verificationCode,
    };
    dispatch(signUpConfirm(args));
  }

  return (
    <Col md={8} lg={6} className="m-auto py-3">
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="email" size="lg">
              <Form.Label>Email</Form.Label>
              <Form.Control
                autoFocus
                type="email"
                value={fields.email}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="verificationCode" size="lg">
              <Form.Label>Verification Code</Form.Label>
              <Form.Control
                autoFocus
                type="text"
                aria-describedby="verificationHelpBlock"
                value={fields.verificationCode}
                onChange={handleFieldChange}
              />
              <Form.Text id="verificationHelpBlock" muted>
                {`Please check ${
                  searchParams.get("email")
                    ? searchParams.get("email")
                    : "your email"
                } for the verification code.`}
              </Form.Text>
            </Form.Group>
            <LoaderButton
              block
              size="lg"
              type="submit"
              variant="success"
              isLoading={isAuthenticating}
              disabled={!validateForm()}
            >
              Verify
            </LoaderButton>
          </Form>
          <div className="mt-2">
            <Link
              to="/signup"
              onClick={() => dispatch(resetVerificationCodeRequested(false))}
            >
              Back to Signup
            </Link>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}
