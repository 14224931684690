import { createSlice } from "@reduxjs/toolkit";

const scenariosSlice = createSlice({
  name: "scenarios",
  initialState: {
    serviceStatus: { pending: false, ready: false, error: false },
    pageNum: 1,
    pageSize: 10,
    sortBy: "id",
    sortDir: "asc",
    summary: { totalCount: 0, totalDuration: 0, totalPages: 0 },
    pagedResults: [],
  },

  reducers: {
    setScenariosServiceInitializing(state) {
      state.serviceStatus.pending = true;
      state.serviceStatus.ready = false;
      state.serviceStatus.error = false;
    },
    setScenariosServiceReady(state) {
      state.serviceStatus.pending = false;
      state.serviceStatus.ready = true;
    },
    setScenariosServiceComputing(state) {
      state.serviceStatus.pending = true;
      state.serviceStatus.error = false;
      state.pageNum = 1;
      state.summary.totalCount = 0;
      state.summary.totalDuration = 0;
      state.summary.totalPages = 0;
    },
    setScenariosServicePending(state) {
      state.serviceStatus.pending = true;
    },
    setScenariosServiceError(state, action) {
      state.serviceStatus.error = action.payload;
    },
    setScenariosPageNum(state, action) {
      state.pageNum = action.payload;
    },
    setScenariosPageSize(state, action) {
      state.pageSize = action.payload;
    },
    setScenariosSortBy(state, action) {
      state.sortBy = action.payload;
    },
    setScenariosSortDir(state, action) {
      state.sortDir = action.payload;
    },
    setPagedScenarioResults(state, action) {
      state.pagedResults = action.payload.results;
      state.pageNum = action.payload.pageNum;
      state.sortBy = action.payload.sortBy;
      state.sortDir = action.payload.sortDir;
      state.serviceStatus.pending = false;
    },
    setScenariosSummary(state, action) {
      const { totalCount, totalDuration } = action.payload;
      state.summary.totalCount = totalCount;
      state.summary.totalDuration = totalDuration;
      state.summary.totalPages = Math.ceil(totalCount / state.pageSize);
    },
  },
});

export const {
  setScenariosServiceInitializing,
  setScenariosServiceReady,
  setScenariosServiceComputing,
  setScenariosServicePending,
  setScenariosServiceError,
  setPagedScenarioResults,
  setScenariosSummary,
  setScenariosPageNum,
  setScenariosPageSize,
  setScenariosSortBy,
  setScenariosSortDir,
} = scenariosSlice.actions;

export const selectScenariosState = (state) => state.scenarios;
export const selectScenariosPageNum = (state) => state.scenarios.pageNum;
export const selectScenariosPageSize = (state) => state.scenarios.pageSize;
export const selectScenariosSortBy = (state) => state.scenarios.sortBy;
export const selectScenariosSortDir = (state) => state.scenarios.sortDir;
export const selectPagedScenariosResults = (state) =>
  state.scenarios.pagedResults;
export const selectScenariosSummary = (state) => state.scenarios.summary || {};
export const selectTotalScenariosCount = (state) =>
  selectScenariosSummary(state).totalCount;
export const selectTotalScenariosDuration = (state) =>
  selectScenariosSummary(state).totalDuration;
export const selectTotalScenariosPages = (state) =>
  selectScenariosSummary(state).totalPages;
export const selectScenariosServiceStatus = (state) =>
  state.scenarios.serviceStatus || {};
export const selectScenariosServiceIsComputing = (state) =>
  selectScenariosServiceStatus(state).pending;
export const selectScenariosServiceHasError = (state) =>
  selectScenariosServiceStatus(state).error;

export default scenariosSlice.reducer;
