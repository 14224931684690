import { createSlice } from "@reduxjs/toolkit";
import {
  selectOperators,
} from "../queryEngine/eventTypes";

const CONDITION_PROTOTYPE = {
  operator: "",
  operands: [],
  valid: false,
};

const initialQueryCriteria = {
  cond0: {
    operator: "in",
    operands: ["map_track", ["junction", "crosswalk"]],
    valid: true,
  },
  cond1: {
    operator: "in",
    operands: ["objects", ["pedestrian"]],
    valid: true,
  },
};

export const queryControlsSlice = createSlice({
  name: "queryControls",
  initialState: {
    minGap: 0.5,
    minDuration: 1,
    categories: [],
    eventTypes: {},
    operators: {
      ...selectOperators(),
    },
    aliases: {},
    currentConditionIndex: 1,
    criteria: initialQueryCriteria,
  },

  reducers: {
    setQueryMinGap(state, action) {
      state.minGap = action.payload;
    },
    setQueryMinDuration(state, action) {
      state.minDuration = action.payload;
    },
    setQueryCategories: {
      reducer(state, action) {
        state.categories = action.payload;
      },
      prepare(payload) {
        return { payload: Object.keys(payload) };
      },
    },
    setQueryEventTypes: {
      reducer(state, action) {
        state.eventTypes = action.payload;
      },
      prepare(payload) {
        return {
          payload: Object.keys(payload).reduce((types, category) => {
            types[category] = Object.keys(payload[category]);
            return types;
          }, {}),
        };
      },
    },
    createQueryCondition(state) {
      const nextIndex = state.currentConditionIndex + 1;
      state.criteria[`cond${nextIndex}`] = CONDITION_PROTOTYPE;
      state.currentConditionIndex = nextIndex;
    },
    updateQueryCondition(state, action) {
      state.criteria = {
        ...state.criteria,
        ...action.payload,
      };
    },
    deleteQueryCondition(state, action) {
      delete state.criteria[action.payload];
    },
  },
});

export const {
  setQueryCategories,
  setQueryEventTypes,
  setQueryMinGap,
  setQueryMinDuration,
  createQueryCondition,
  updateQueryCondition,
  deleteQueryCondition,
} = queryControlsSlice.actions;

export const selectQueryMinGap = (state) => state.queryControls.minGap;
export const selectQueryMinDuration = (state) =>
  state.queryControls.minDuration;
export const selectQueryCriteria = (state) => state.queryControls.criteria;
export const selectQueryCategories = (state) => state.queryControls.categories;
export const selectQueryEventTypes = (state) => state.queryControls.eventTypes;
export const selectQueryOperators = (state) => state.queryControls.operators;

export function selectQueryEventTypesForColumn(column) {
  return function (state) {
    return selectQueryEventTypes(state)[column] || {};
  };
}

export function selectQueryOperatorsForColumn(column) {
  return function (state) {
    return selectQueryOperators(state)[column] || [];
  };
}

export function selectQueryCondition(id) {
  return function (state) {
    return selectQueryCriteria(state)[id] || {};
  };
}

export function selectQueryConditionOperator(id) {
  return function (state) {
    return selectQueryCondition(id)(state).operator || "";
  };
}

export function selectQueryConditionOperands(id) {
  return function (state) {
    const operands = selectQueryCondition(id)(state).operands || [];
    const lhs = operands[0] || "";
    const rhs = operands[1] || "";
    return [lhs, rhs];
  };
}

export default queryControlsSlice.reducer;
