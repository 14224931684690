import { eventTypeMappings } from "./eventTypeMappings";

export function selectCategories() {
  return Object.values(eventTypeMappings.categories);
}

export function selectOperators() {
  return eventTypeMappings.operators;
}

export function selectEventTypesForCategory(category) {
  const eventTypes = eventTypeMappings.types[category] || {};
  if (eventTypes == null) {
    console.warn(`No event types for category id: (${category})`);
    return eventTypes;
  }
  return Object.values(eventTypes).filter(
    (eventType) => eventType.name !== "unclassified"
  );
}

export function selectAllEventTypes() {
  const categories = selectCategories();
  let eventTypes = {};

  categories.forEach((category) => {
    eventTypes[category.name] = selectEventTypesForCategory(category.name);
  });

  return eventTypes;
}
