import "./ScenarioDetail.css";
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/esm/Row";
import VideoNavigator from "../../containers/VideoNavigator";
import ObjectTracksChartMap from "../../components/ObjectTracksChartMap";
import { MdLaunch } from "react-icons/md";
import { onError } from "../../libs/alert-lib";

export default function ScenarioDetail(props) {
  const { eventInfo } = props;
  const [objectTracksLoading, setObjectTracksLoading] = useState(false);
  const [objectTracksResponse, setObjectTracksResponse] = useState();
  const [scenarioSpec, setScenarioSpec] = useState({
    metadata: {},
    actors: [],
  });
  const [showModal, setShowModal] = useState(false);
  const tStart = eventInfo.tStart;
  const tEnd = eventInfo.tEnd;
  const tripId = eventInfo.tripId;
  const mapId = eventInfo.tripInfo.mapId;
  const mapInfo = eventInfo.tripInfo.mapInfo || {};

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    async function startObjectTracksRequest() {
      console.debug("OBJECT TRACKS REQUEST STARTING FOR", tripId, tStart, tEnd);
      try {
        setObjectTracksLoading(true);
        const requestBody = {
          body: {
            t_start: tStart,
            t_end: tEnd,
            object_ids: [],
          },
        };
        const response = await API.post(
          "object_tracks",
          `/object_tracks/${tripId}`,
          requestBody
        );
        setObjectTracksResponse(response.results);
        setObjectTracksLoading(false);
        setScenarioSpec({
          metadata: {
            drive_id: tripId,
            map_id: mapId,
            t_start: tStart,
            t_end: tEnd,
            duration: eventInfo.duration,
            tags: getTagValues(eventInfo),
          },
          actors: response.actors,
        });
      } catch (e) {
        console.error(e);
        onError(e);
        setObjectTracksLoading(false);
      }
    }

    if (tripId && tStart != null && tEnd != null) {
      startObjectTracksRequest();
    }
  }, [eventInfo, tEnd, tStart, tripId, mapId]);

  return (
    <React.Fragment>
      <Row className="border-top py-3 px-3 bg-light">
        <Col xl="6">
          <Card>
            <Card.Body>
              <ObjectTracksChartMap
                isLoading={objectTracksLoading}
                data={objectTracksResponse}
                tStart={tStart}
                tEnd={tEnd}
                mapId={mapId}
                mapInfo={mapInfo}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <VideoNavigator tripId={tripId} tStart={tStart} tEnd={tEnd} />
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={handleShowModal}
          >
            Scenario Spec <MdLaunch />
          </Button>
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Scenario Spec</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "30rem", overflow: "auto" }}>
          <pre>
            <code>{formatScenarioSpec(scenarioSpec)}</code>
          </pre>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() =>
              download(
                formatScenarioSpec(scenarioSpec),
                "scenario-spec.json",
                "text/plain"
              )
            }
          >
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

function formatScenarioSpec(scenarioSpec) {
  try {
    return JSON.stringify(scenarioSpec, null, 2);
  } catch (e) {
    const errorMsg = "error formatting scenario spec";
    console.error(errorMsg, e);
    return errorMsg;
  }
}

function getScenarioEvents(eventsByCategory) {
  let scenarioEvents = {};
  Object.keys(eventsByCategory).forEach((category) => {
    const events = eventsByCategory[category];
    Object.keys(events).forEach((eventType) => {
      const eventCount = events[eventType].length;
      const key = `${category}:${eventType}`
      scenarioEvents[key] = eventCount;
    });
  });
  return scenarioEvents;
}

function getTagValues(eventInfo) {
  try {
    return getScenarioEvents(eventInfo.events)
  } catch (e) {
    const errorMsg = "error formatting tags";
    console.error(errorMsg, e);
    return [errorMsg];
  }
}

function download(content, fileName, contentType) {
  var a = document.createElement("a");
  var file = new Blob([content], { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
}
