import "./ResultsTable.css";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/esm/Modal";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

import {
  MdArrowDownward,
  MdArrowUpward,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdExpandLess,
  MdExpandMore,
  MdIndeterminateCheckBox,
  MdFileDownload,
} from "react-icons/md";
import { formatDate, formatInterval, formatTime } from "../../libs/date-utils";
import ScenarioDetail from "./ScenarioDetail";
import { submitForAnnotation } from "../annotationService/submitForAnnotation";
import TableControls from "./TableControls";
import {
  selectPagedScenariosResults,
  selectScenariosPageNum,
  selectScenariosPageSize,
  selectScenariosServiceHasError,
  selectScenariosServiceIsComputing,
  selectScenariosSortBy,
  selectScenariosSortDir,
  selectTotalScenariosCount,
  selectTotalScenariosDuration,
  setScenariosServiceError,
} from "./scenariosSlice";

import {
  selectAnnotationBatchName,
  selectAnnotationTaskType,
  selectAnnotationSubSamplingCount,
  setAnnotationBatchName,
  setAnnotationTaskType,
  setAnnotationSubSamplingCount,
} from "../annotationService/annotationSlice";

import { selectTripInfoById } from "../driveEvents/eventsDataSlice";
import { sortScenarioResults } from "../queryEngine/dataClient";

const colorMap = {
  camera2d_objects: { backgroundColor: "#28a745", color: "white" },
  ego: { backgroundColor: "#dc3545", color: "white" },
  interaction: { backgroundColor: "#ffc107" },
  map_track: { backgroundColor: "#17a2b8", color: "white" },
  objects: { backgroundColor: "#343a40", color: "white" },
};

export default function ResultsTable() {
  const [selectedRows, setSelectedRows] = useState({ count: 0, rows: [] });
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showAnnotationModal, setShowAnnotationModal] = useState(false);
  const [activeRow, setActiveRow] = useState();
  const dispatch = useDispatch();

  const isComputing = useSelector(selectScenariosServiceIsComputing);
  const hasError = useSelector(selectScenariosServiceHasError);
  const totalScenariosCount = useSelector(selectTotalScenariosCount);
  const totalScenariosDuration = useSelector(selectTotalScenariosDuration);
  const pageNum = useSelector(selectScenariosPageNum);
  const pageSize = useSelector(selectScenariosPageSize);
  const pagedResults = useSelector(selectPagedScenariosResults);
  const sortColumn = useSelector(selectScenariosSortBy);
  const sortDir = useSelector(selectScenariosSortDir);

  const hasResults = Array.isArray(pagedResults) && pagedResults.length > 0;
  const rowEnd = pageNum * pageSize;
  const rowStart = rowEnd - pageSize + 1;
  const resultsMessage = totalScenariosCount
    ? `Results ${rowStart}-${Math.min(
        rowEnd,
        totalScenariosCount
      )} of ${totalScenariosCount}`
    : "";

  useEffect(() => {
    const res = Array.isArray(pagedResults) ? pagedResults : [];
    const selectedState = false;
    setSelectedRows({ count: 0, rows: res.map(() => selectedState) });
  }, [pagedResults]);

  const handleCloseDownloadModal = () => setShowDownloadModal(false);
  const handleCloseAnnotationModal = () => setShowAnnotationModal(false);

  function handleSelectAll() {
    const shouldSelectAll = selectedRows.count === 0;
    const count = shouldSelectAll ? pagedResults.length : 0;
    const rows = pagedResults.map(() => shouldSelectAll);
    setSelectedRows({ count, rows });
  }

  function handleRowSelect(rowindex) {
    const rows = selectedRows.rows.map((selectedState, index) => {
      if (index !== rowindex) return selectedState;
      return !selectedState;
    });
    const count = rows.reduce(
      (total, selectedState) => (selectedState ? total + 1 : total),
      0
    );
    setSelectedRows({ count, rows });
  }

  function handleToggleRowCollapse(rowindex) {
    setActiveRow(activeRow === rowindex ? null : rowindex);
  }

  return (
    <React.Fragment>
      <Card className="mb-3">
        <Card.Header>
          <TableTopper
            onDownloadSelectedClick={() => setShowDownloadModal(true)}
            onAnnotationSelectedClick={() => setShowAnnotationModal(true)}
            selectedCount={selectedRows.count}
            totalRows={totalScenariosCount}
            totalDuration={totalScenariosDuration}
            pageNum={pageNum}
            pageSize={pageSize}
          />

          {hasError && (
            <Alert
              variant="danger"
              onClose={() => dispatch(setScenariosServiceError(false))}
              dismissible
              className="mt-2 mb-0"
            >
              <Alert.Heading>Error computing scenarios</Alert.Heading>
              <p>
                Please see the developer console for more details or try another
                query
              </p>
            </Alert>
          )}
        </Card.Header>

        <div className="table-responsive auro-data-table">
          <Accordion>
            <Table>
              <TableHead
                onSelectAllClick={handleSelectAll}
                resultsCount={pagedResults.length}
                isComputing={isComputing}
                selectedCount={selectedRows.count}
                sortColumn={sortColumn}
                sortDir={sortDir}
                handleSortClick={(sortColumn) =>
                  sortScenarioResults(sortColumn)
                }
              />
              <tbody style={{ opacity: isComputing ? 0.6 : 1 }}>
                {hasResults &&
                  pagedResults.map((row, index) => (
                    <TableRow
                      key={`scenario-${row.id}`}
                      row={row}
                      index={index}
                      rowIsSelected={selectedRows.rows[index] ? 1 : 0}
                      rowIsToggled={index === activeRow}
                      onRowSelect={() => handleRowSelect(index)}
                      onRowToggle={() => handleToggleRowCollapse(index)}
                    />
                  ))}
              </tbody>
            </Table>
          </Accordion>
        </div>

        {!hasResults && isComputing && (
          <div className="py-5 text-center">
            <div className="spinner-border text-secondary">
              <span className="sr-only">Loading…</span>
            </div>
          </div>
        )}

        <Card.Body className="border-top d-flex justify-content-end">
          <div>
            <span style={{ lineHeight: 2.4 }} className="mr-3">
              {resultsMessage}
            </span>
          </div>
          <TableControls />
        </Card.Body>
      </Card>

      <Modal show={showDownloadModal} onHide={handleCloseDownloadModal}>
        <Modal.Header closeButton>
          <Modal.Title>Selected Scenarios</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "25rem", overflow: "auto" }}>
          <pre>
            <code>
              {showDownloadModal &&
                renderDownloadContent(
                  pagedResults.filter((_, index) => selectedRows.rows[index])
                )}
            </code>
          </pre>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() =>
              download(
                renderDownloadContent(
                  pagedResults.filter((_, index) => selectedRows.rows[index])
                ),
                "selected-scenarios.json",
                "text/plain"
              )
            }
          >
            Download
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAnnotationModal} onHide={handleCloseAnnotationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Request for annotation</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "50rem", overflow: "auto" }}>
          {showAnnotationModal && <RenderAnnotationContent />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              handleCloseAnnotationModal(); //close modal, tasks will continue to run in background
              const args = {
                filteredResults: pagedResults.filter(
                  (_, index) => selectedRows.rows[index]
                ),
              };
              dispatch(submitForAnnotation(args));
            }}
          >
            Submit for annotation
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

function CheckButton(props) {
  let icon;
  switch (props.selectedState) {
    case 0:
      icon = <MdCheckBoxOutlineBlank />;
      break;
    case 1:
      icon = <MdCheckBox />;
      break;
    default:
      icon = <MdIndeterminateCheckBox />;
      break;
  }

  return (
    <button
      disabled={props.disabled}
      className="btn btn-light btn-sm"
      onClick={props.onClick}
    >
      {icon}
    </button>
  );
}

function TableTopper(props) {
  const { selectedCount, totalDuration } = props;
  const durationMessage = totalDuration
    ? `Total duration ${formatInterval(totalDuration)}`
    : "";

  return (
    <div className="d-flex justify-content-between text-secondary">
      <div>
        <Button
          size="sm"
          variant="outline-secondary"
          disabled={selectedCount === 0}
          className="mr-3"
          onClick={props.onDownloadSelectedClick}
        >
          Download {selectedCount} Selected <MdFileDownload />
        </Button>
        <Button
          size="sm"
          variant="outline-secondary"
          disabled={selectedCount === 0}
          onClick={props.onAnnotationSelectedClick}
        >
          Export for Annotation
        </Button>
      </div>
      <div style={{ lineHeight: 1.9 }}>{durationMessage}</div>
    </div>
  );
}

function SortControl(props) {
  let sortIndicator = (
    <span className="invisible col-sort-icon">
      <MdArrowUpward />
    </span>
  );
  if (props.sortActive) {
    sortIndicator =
      props.sortDir === "desc" ? (
        <span className="col-sort-icon">
          <MdArrowDownward />
        </span>
      ) : (
        <span className="col-sort-icon">
          <MdArrowUpward />
        </span>
      );
  }

  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      className="col-sortable btn btn-link d-flex"
    >
      <span className="col-title text-nowrap mr-1">{props.label}</span>
      {sortIndicator}
    </button>
  );
}

function TableHead(props) {
  const { resultsCount, selectedCount, isComputing } = props;
  const buttonSelectedState =
    selectedCount === 0 ? 0 : selectedCount === resultsCount ? 1 : -1;
  const buttonDisabledState = isComputing || resultsCount === 0;
  return (
    <thead className="thead-secondary">
      <tr>
        <th>
          <CheckButton
            disabled={buttonDisabledState}
            selectedState={buttonSelectedState}
            onClick={props.onSelectAllClick}
          />
        </th>
        <th></th>
        <th>
          <SortControl
            label="Start"
            disabled={buttonDisabledState}
            onClick={() => props.handleSortClick("startTime")}
            sortDir={props.sortDir}
            sortActive={props.sortColumn === "startTime"}
          />
        </th>
        <th>
          <SortControl
            label="Duration"
            disabled={buttonDisabledState}
            onClick={() => props.handleSortClick("duration")}
            sortDir={props.sortDir}
            sortActive={props.sortColumn === "duration"}
          />
        </th>
        <th>
          <SortControl disabled label="Tags" />
        </th>
        <th>
          <SortControl
            label="Trip ID"
            disabled={buttonDisabledState}
            onClick={() => props.handleSortClick("tripId")}
            sortDir={props.sortDir}
            sortActive={props.sortColumn === "tripId"}
          />
        </th>
      </tr>
    </thead>
  );
}

function EventTag(props) {
  const tagStyle = colorMap[props.category];
  // const categoryInitial = props.category.charAt(0).toUpperCase();
  return (
    <div style={tagStyle} className="event-tag">
      {/* <div style={categoryStyle} className="event-badge badge-left">
        {categoryInitial}
      </div> */}
      <span className="event-label">{props.type}</span>
      <div className="event-badge badge-right">{props.count}</div>
    </div>
  );
}

function TableRow({
  row,
  index,
  rowIsSelected,
  rowIsToggled,
  onRowSelect,
  onRowToggle,
}) {
  const tripInfo = useSelector(selectTripInfoById({ tripId: row.tripId }));
  // combine scenario results with general trip info for use in computing scenario detail
  const eventInfo = { ...row, tripInfo };
  const startTime = new Date(row.startTime);

  return (
    <React.Fragment>
      <tr id={`scenario-${row.id}`}>
        <td>
          <CheckButton selectedState={rowIsSelected} onClick={onRowSelect} />
        </td>
        <td>
          <Accordion.Toggle
            as={Button}
            variant="light"
            eventKey={`accordion-${index}`}
            className="btn-sm"
            onClick={onRowToggle}
          >
            {rowIsToggled ? <MdExpandLess /> : <MdExpandMore />}
          </Accordion.Toggle>
        </td>
        <td>
          {formatDate(startTime)} {formatTime(startTime)}
        </td>
        <td>{formatInterval(row.duration)}</td>
        <td>
          <div className="event-tags-list">{renderTagsList(row.events)}</div>
        </td>
        <td>
          <div className="id-tag">
            <div className="event-tag">
              <div title={row.tripId} className="text-truncate event-label">
                {row.tripId}
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan="6" style={{ padding: 0, border: 0 }}>
          <Accordion.Collapse eventKey={`accordion-${index}`}>
            {rowIsToggled ? <ScenarioDetail eventInfo={eventInfo} /> : <div />}
          </Accordion.Collapse>
        </td>
      </tr>
    </React.Fragment>
  );
}

function renderTagsList(eventsByCategory) {
  let tagsList = [];
  Object.keys(eventsByCategory).forEach((category) => {
    const events = eventsByCategory[category];
    Object.keys(events).forEach((eventType) => {
      const eventCount = events[eventType].length;
      tagsList.push(
        <EventTag
          key={`tag-${category}-${eventType}`}
          category={category}
          type={eventType}
          count={eventCount}
        />
      );
    });
  });
  return tagsList;
}

function renderDownloadContent(selectedResults) {
  const out = selectedResults.map((row) => {
    try {
      return {
        metadata: {
          drive_id: row.tripId,
          t_start: row.tStart,
          t_end: row.tEnd,
          duration: row.duration,
          tags: getTagValues(row),
        },
        actors: Array.from(
          new Set([...(row.actor_id || []), ...(row.object_id || [])])
        ),
      };
    } catch (e) {
      const errorMsg = "error formatting event";
      console.error(errorMsg, e);
      return { message: errorMsg };
    }
  });
  return formatScenarioSpec(out);
}

function RenderAnnotationContent() {
  const annotationBatchName = useSelector(selectAnnotationBatchName);
  const annotationTaskType = useSelector(selectAnnotationTaskType);
  const annotationSubSamplingCount = useSelector(
    selectAnnotationSubSamplingCount
  );
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Form>
        <Form.Row>
          <Form.Control
            type="text"
            placeholder="Batch name"
            value={annotationBatchName}
            onChange={(event) => {
              dispatch(setAnnotationBatchName(event.target.value));
            }}
          />
        </Form.Row>
        <fieldset>
          <Form.Row>
            <Form.Label as="legend" column sm={2}>
              Task type
            </Form.Label>
            <Col sm={10}>
              <Form.Group>
                <Form.Check
                  type="radio"
                  label="2D Bounding Box"
                  name="radio"
                  checked={annotationTaskType === "bounding_box"}
                  onChange={(event) => {
                    dispatch(setAnnotationTaskType("bounding_box"));
                  }}
                />
                <Form.Check
                  type="radio"
                  label="Semantic segmentation"
                  name="radio"
                  checked={annotationTaskType === "semantic_segmentation"}
                  onChange={(event) => {
                    dispatch(setAnnotationTaskType("semantic_segmentation"));
                  }}
                />
              </Form.Group>
            </Col>
          </Form.Row>
        </fieldset>
        <Form.Row>
          <Form.Control
            type="number"
            placeholder="Subsample every nth image"
            value={annotationSubSamplingCount}
            onChange={(event) => {
              dispatch(setAnnotationSubSamplingCount(event.target.value));
            }}
          />
        </Form.Row>
      </Form>
    </React.Fragment>
  );
}

function formatScenarioSpec(scenarioSpec) {
  try {
    return JSON.stringify(scenarioSpec, null, 2);
  } catch (e) {
    const errorMsg = "error formatting scenario spec";
    console.error(errorMsg, e);
    return errorMsg;
  }
}

function getScenarioEvents(eventsByCategory) {
  let scenarioEvents = {};
  Object.keys(eventsByCategory).forEach((category) => {
    const events = eventsByCategory[category];
    Object.keys(events).forEach((eventType) => {
      const eventCount = events[eventType].length;
      const key = `${category}:${eventType}`;
      scenarioEvents[key] = eventCount;
    });
  });
  return scenarioEvents;
}

function getTagValues(eventInfo) {
  try {
    return getScenarioEvents(eventInfo.events);
  } catch (e) {
    const errorMsg = "error formatting tags";
    console.error(errorMsg, e);
    return [errorMsg];
  }
}

function download(content, fileName, contentType) {
  var a = document.createElement("a");
  var file = new Blob([content], { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
}
