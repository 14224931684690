import React from "react";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/esm/Button";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
import {
  MdFirstPage,
  MdLastPage,
  MdNavigateBefore,
  MdNavigateNext,
} from "react-icons/md";
import {
  selectScenariosPageNum,
  selectTotalScenariosPages,
} from "./scenariosSlice";
import { jumpToScenarioResultsPage } from "../queryEngine/dataClient";

export default function TableControls() {
  const currentPageNum = useSelector(selectScenariosPageNum);
  const totalPageCount = useSelector(selectTotalScenariosPages);

  function jumpToFirstPage() {
    const goToPage = 1;
    jumpToScenarioResultsPage(goToPage);
  }

  function jumpToPrevPage() {
    const goToPage = Math.max(currentPageNum - 1, 1);
    jumpToScenarioResultsPage(goToPage);
  }

  function jumpToNextPage() {
    const goToPage = Math.min(currentPageNum + 1, totalPageCount);
    jumpToScenarioResultsPage(goToPage);
  }

  function jumpToLastPage() {
    const goToPage = totalPageCount;
    jumpToScenarioResultsPage(goToPage);
  }

  return (
    <ButtonGroup>
      <Button
        disabled={currentPageNum <= 1}
        onClick={jumpToFirstPage}
        variant="outline-secondary"
      >
        <MdFirstPage />
      </Button>
      <Button
        disabled={currentPageNum <= 1}
        onClick={jumpToPrevPage}
        variant="outline-secondary"
      >
        <MdNavigateBefore />
      </Button>
      <Button
        disabled={!totalPageCount || currentPageNum >= totalPageCount}
        onClick={jumpToNextPage}
        variant="outline-secondary"
      >
        <MdNavigateNext />
      </Button>
      <Button
        disabled={!totalPageCount || currentPageNum >= totalPageCount}
        onClick={jumpToLastPage}
        variant="outline-secondary"
      >
        <MdLastPage />
      </Button>
    </ButtonGroup>
  );
}
