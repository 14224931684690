import { createAsyncThunk } from "@reduxjs/toolkit";
import { alertInfo } from "../../libs/alert-lib";

import {
  selectAnnotationBatchName,
  selectAnnotationTaskType,
  selectAnnotationSubSamplingCount,
} from "./annotationSlice";

export const submitForAnnotation = createAsyncThunk(
  "annotation/submitForAnnotation",
  async (args, thunkApi) => {
    const state = thunkApi.getState();
    const cameraTopic = "/apollo/sensor/camera/4mmA/image/compressed";
    const recordRenderingServiceAddr = "http://localhost:8051";
    const annotationServiceAddr = "http://127.0.0.1:8053";
    const batchName = selectAnnotationBatchName(state);
    const taskType = selectAnnotationTaskType(state);
    const subSampleCount = selectAnnotationSubSamplingCount(state);
    const selectedResults = args.filteredResults;

    // 1. Fetch trip id from external trip id
    // 2. Fetch topic id for camera topic
    // 3. Create a processed record file with trip id, whitelist camera topic and start, end times
    // 4. Extract processed record file with batch name as folder name
    // 5. Create a Scale batch with batch name and extracted location in test env based on task type
    // 6. Submit batch for annotation using batch id

    // Step 1 - get trip id within record rendering service
    let recordRenderingServiceTripId = await fetch(
      recordRenderingServiceAddr +
        "/api/v1/trips?external_trip_id=" +
        selectedResults[0]["tripId"]
    )
      .then((response) => response.json())
      .then((trips) => {
        if (trips && trips.length > 0) {
          return trips[0]["id"];
        } else {
          throw new Error("No matching trip found");
        }
      });
    console.log(
      "Trip id within record rendering service fetched:" +
        recordRenderingServiceTripId
    );

    // Step 2 - get camera topic id from topic name
    let cameraTopicId = await fetch(
      recordRenderingServiceAddr + "/api/v1/topics?name=" + cameraTopic
    )
      .then((response) => response.json())
      .then((topics) => {
        if (topics && topics.length > 0) {
          return topics[0]["id"];
        } else {
          throw new Error("No matching camera topic found");
        }
      });
    console.log("Camera topic id fetched:" + cameraTopicId);

    // Step 3 - create processed record file based on event time window
    console.log(
      "Submitting request for processing record files, start_time:" +
        new Date(selectedResults[0]["startTime"]) +
        ",end_time:" +
        new Date(
          selectedResults[0]["startTime"] +
            selectedResults[0]["duration"] * 1000
        )
    );
    let processedRecordFileId = await fetch(
      recordRenderingServiceAddr + "/api/v1/trip_files",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          trip_id: recordRenderingServiceTripId,
          whitelist_topic_ids: [cameraTopicId],
          start_time: new Date(selectedResults[0]["startTime"]),
          end_time: new Date(
            selectedResults[0]["startTime"] +
              selectedResults[0]["duration"] * 1000
          ),
        }),
      }
    )
      .then((response) => response.json())
      .then((processed_file) => {
        if (processed_file) {
          return processed_file["id"];
        } else {
          throw new Error("Error while creating processed record file");
        }
      });
    console.log("Processed record file created:" + processedRecordFileId);

    // Step 4 - extract images from processed record file
    console.log(
      "Submitting request to extract messages from processed record file"
    );
    let extractedLocation = await fetch(
      recordRenderingServiceAddr +
        "/api/v1/trip_files/" +
        processedRecordFileId +
        "/extract_messages",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          folder_name: batchName,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response) {
          return response["extracted_location"];
        } else {
          throw new Error(
            "Error while extracting messagers from processed record file"
          );
        }
      });

    console.log(
      "Messages extracted from processed record file, location:" +
        extractedLocation
    );
    // Step 5 - Create Batch in scale
    let batchId = await fetch(annotationServiceAddr + "/api/v1/batches", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: batchName,
        is_test: true,
        task_type: taskType,
        locator_id: extractedLocation,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response) {
          return response["id"];
        } else {
          throw new Error("Error while creating Scale batch");
        }
      });
    console.log(
      "Scale batch created for submitting annotation request, batch_id:" +
        batchId
    );

    // Step 6 - Submit batch for annotation
    console.log("Uploading images to S3 for submitting tasks to Scale");
    let batchStatus = await fetch(
      annotationServiceAddr + "/api/v1/batches/" + batchId,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sampling_frequency: parseInt(subSampleCount),
          submitted_at: new Date().toISOString(),
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response) {
          return response["status"];
        } else {
          throw new Error("Error while submitting Scale batch for annotation");
        }
      });
    console.log(
      "Annotation tasks submitted successfully, batch_status:" + batchStatus
    );

    if (batchStatus === "in_progress") {
      alertInfo("Annotation request submitted successfully!");
    }
  }
);
