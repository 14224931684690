import { createSlice } from "@reduxjs/toolkit";
import { getLocalStorage, setLocalStorage } from "../../libs/localstorage-lib";

const EVENTS_DATA_STATE_KEY = "NemoApp.EVENTS_DATA_STATE";
const DEFAULT_STATE = {
  scopeIsSet: false,
  scopeFrom: "2020-09-30",
  scopeTo: "2020-10-31",
  tripIds: [],
  trips: {},
};

export const tripTableStatus = {
  START: "START",
  FETCHING: "FETCHING",
  DESERIALIZING: "DESERIALIZING",
  SAVING: "SAVING",
  ERROR: "ERROR",
  READY: "READY",
};

export const eventsDataSlice = createSlice({
  name: "eventsData",
  initialState: {
    ...getInitialState(DEFAULT_STATE, EVENTS_DATA_STATE_KEY),
    serviceStatus: { total: 0, initializing: 0, ready: 0, error: 0 },
  },

  reducers: {
    setDataScopeIsSet(state, action) {
      state.scopeIsSet = action.payload;
    },
    setDataScopeFrom(state, action) {
      state.scopeFrom = action.payload;
    },
    setDataScopeTo(state, action) {
      state.scopeTo = action.payload;
    },
    setEventsServiceInitializing(state, action) {
      const trips = action.payload;

      const tableCounts = Object.values(trips)
        .map((item) => item.tables.length)
        .reduce((total, count) => total + count, 0);

      state.serviceStatus.total = tableCounts;
      state.serviceStatus.ready = 0;
      state.serviceStatus.error = 0;
      state.serviceStatus.initializing = tableCounts;
    },
    setEventsServiceReady(state) {
      state.serviceStatus.ready += 1;
      state.serviceStatus.initializing -= 1;
    },
    setEventsServiceError(state) {
      state.serviceStatus.error += 1;
      state.serviceStatus.initializing -= 1;
    },
    setTripTableStatuses(state, action) {
      const { tripId, statuses } = action.payload;
      try {
        state.trips[tripId].statuses = statuses;

        Object.values(statuses).forEach((status) => {
          if (status === tripTableStatus.READY) {
            state.serviceStatus.ready += 1;
            state.serviceStatus.initializing -= 1;
          } else if (status === tripTableStatus.ERROR) {
            state.serviceStatus.error += 1;
            state.serviceStatus.initializing -= 1;
          }
        });
      } catch (error) {
        console.error(`setTripTableStatus error for ${tripId}`, error);
      }
    },
    setTripTableStatus(state, action) {
      const { tripId, tableName, status } = action.payload;
      try {
        state.trips[tripId].statuses[tableName] = status;

        if (status === tripTableStatus.READY) {
          state.serviceStatus.ready += 1;
          state.serviceStatus.initializing -= 1;
        } else if (status === tripTableStatus.ERROR) {
          state.serviceStatus.error += 1;
          state.serviceStatus.initializing -= 1;
        }
      } catch (error) {
        console.error(
          `setTripTableStatus error for ${tripId} ${tableName}`,
          error
        );
      }
    },
    setTrips(state, action) {
      state.trips = action.payload.trips;
      state.tripIds = action.payload.tripIds;
    },
  },
});

export const {
  setDataScopeFrom,
  setDataScopeTo,
  setDataScopeIsSet,
  setEventsServiceInitializing,
  setEventsServiceReady,
  setEventsServiceError,
  setTrips,
  setTripTableStatus,
  setTripTableStatuses,
} = eventsDataSlice.actions;

export function selectEventsDataState(state) {
  return state.eventsData;
}

export function selectDataScopeIsSet(state) {
  return state.eventsData.scopeIsSet;
}

export function selectDataScope(state) {
  return {
    from: state.eventsData.scopeFrom,
    to: state.eventsData.scopeTo,
  };
}

export function selectEventsServiceStatus(state) {
  return state.eventsData.serviceStatus || {};
}

export function selectTrips(state) {
  return state.eventsData.trips || {};
}

export function selectTripIds(state) {
  return state.eventsData.tripIds || [];
}

export function selectTripInfoById(props) {
  return function (state) {
    return state.eventsData.trips[props.tripId] || {};
  };
}

export function selectTripTableStatuses(state, props) {
  return selectTripInfoById(state, props).statuses || {};
}

export function updateTripsState(updatedTrips) {
  return (dispatch, getState) => {
    const state = getState();
    const dataScope = selectDataScope(state);
    const tripIds = Object.keys(updatedTrips);
    dispatch(setDataScopeIsSet(true));
    dispatch(setTrips({ trips: updatedTrips, tripIds }));
    dispatch(setEventsServiceInitializing(updatedTrips));
    setLocalStorage(EVENTS_DATA_STATE_KEY, {
      scopeIsSet: true,
      scopeFrom: dataScope.from,
      scopeTo: dataScope.to,
      trips: updatedTrips,
      tripIds,
    });
  };
}

function getInitialState(defaultState = {}) {
  const stateFromStorage = getLocalStorage(EVENTS_DATA_STATE_KEY);
  if (stateFromStorage == null) return defaultState;
  return {
    ...defaultState,
    ...stateFromStorage,
  };
}

export default eventsDataSlice.reducer;
