import "./Login.css";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/Form";
import LoaderButton from "../../components/LoaderButton";
import { useFormFields } from "../../libs/hooks-lib";
import {
  forgotPasswordComplete,
  resetAuthState,
  selectIsAuthenticating,
} from "./authSlice";
import { Link } from "react-router-dom";

export default function LoginForgotVerify() {
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const [fields, handleFieldChange] = useFormFields({
    email: searchParams.get("email") || "",
    verificationCode: "",
    newPassword: "",
    confirmPassword: "",
  });

  const isAuthenticating = useSelector(selectIsAuthenticating);

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.verificationCode.length > 0 &&
      fields.newPassword.length > 0 &&
      fields.newPassword === fields.confirmPassword
    );
  }

  function handleSubmit(event) {
    event.preventDefault();

    const args = {
      username: fields.email,
      code: fields.verificationCode,
      newPassword: fields.newPassword,
    };
    dispatch(forgotPasswordComplete(args));
  }

  return (
    <Col md={8} lg={6} className="m-auto py-3">
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={fields.email}
                disabled={isAuthenticating}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group size="lg" controlId="verificationCode">
              <Form.Label>Verification Code</Form.Label>
              <Form.Control
                autoFocus
                type="text"
                aria-describedby="verificationHelpBlock"
                value={fields.verificationCode}
                disabled={isAuthenticating}
                onChange={handleFieldChange}
              />
              <Form.Text id="verificationHelpBlock" muted>
                {`Please check ${
                  searchParams.get("email")
                    ? searchParams.get("email")
                    : "your email"
                } for the verification code.`}
              </Form.Text>
            </Form.Group>
            <hr />
            <Form.Group size="lg" controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                autoComplete="off"
                value={fields.password}
                disabled={isAuthenticating}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group size="lg" controlId="confirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                autoComplete="off"
                value={fields.confirmPassword}
                disabled={isAuthenticating}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <LoaderButton
              block
              type="submit"
              size="lg"
              isLoading={isAuthenticating}
              disabled={!validateForm()}
            >
              Reset Password
            </LoaderButton>
          </Form>
          <div className="mt-2">
            <Link to="/login" onClick={() => dispatch(resetAuthState())}>
              Back to Login
            </Link>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}
