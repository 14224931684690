import React from "react";
import { useSelector } from "react-redux";

import BarChart from "../../components/BarChart";
import { selectEventStatsAll } from "./statsSlice";

export default function EventStatsAllChart() {
  const eventStatsAll = useSelector(selectEventStatsAll);

  return (
    <div className="mx-auto" style={{ maxWidth: "800px", minHeight: "250px" }}>
      <BarChart
        isLoading={null}
        data={eventStatsAll}
        title="Events by Category"
        xAxisTitle="Duration (minutes)"
      />
    </div>
  );
}
