import React, { useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { MdReplay } from "react-icons/md";
import Video from "../components/Video";

const BASE_URL =
  "https://nemo-data-query-engine-data.s3-us-west-2.amazonaws.com/video/out/webm";

export default function VideoNavigator(props) {
  const src = `${props.tripId}_1280x720_1000k.webm`;
  const tInterval = `#t=${props.tStart},${props.tEnd}`;
  const [forceReload, setForceReload] = useState(false);

  function onReplayClick() {
    setForceReload(true);
    setTimeout(() => setForceReload(false), 1000);
  }
  return (
    <>
      <div className="embed-responsive embed-responsive-16by9">
        <Video
          src={`${BASE_URL}/${props.tripId}/${src}${tInterval}`}
          controls
          autoPlay
          forceReload={forceReload}
        />
      </div>
      <div className="py-2">
        <Button
          variant="dark"
          size="sm"
          disabled={forceReload}
          onClick={onReplayClick}
        >
          Replay <MdReplay />
        </Button>
      </div>
    </>
  );
}
