import { Auth } from "aws-amplify";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { alertInfo, onError } from "../../libs/alert-lib";

const USER_CHALLENGE_NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED";

export const checkCurrentSession = createAsyncThunk(
  "auth/checkCurrentSession",
  async () => {
    await Auth.currentSession();
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (args) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, args.oldPassword, args.newPassword);
  }
);

export const completeNewPassword = createAsyncThunk(
  "auth/completeNewPassword",
  async (args) => {
    const user = await Auth.signIn(args.username, args.tempPassword);
    await Auth.completeNewPassword(user, args.newPassword);
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (username) => {
    await Auth.forgotPassword(username);
  }
);

export const forgotPasswordComplete = createAsyncThunk(
  "auth/forgotPasswordComplete",
  async (args) => {
    await Auth.forgotPasswordSubmit(args.username, args.code, args.newPassword);
  }
);

export const login = createAsyncThunk("auth/login", async (args) => {
  const user = await Auth.signIn(args.username, args.password);
  return user.challengeName;
});

export const logout = createAsyncThunk("auth/logout", async () => {
  await Auth.signOut();
});

export const signUp = createAsyncThunk("auth/signUp", async (args) => {
  await Auth.signUp(args);
});

export const signUpConfirm = createAsyncThunk(
  "auth/signUpConfirm",
  async (args) => {
    await Auth.confirmSignUp(args.username, args.code);
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    isAuthenticating: false,
    newPasswordRequired: false,
    verificationCodeRequested: false,
  },

  reducers: {
    resetAuthState(state) {
      state.isAuthenticated = false;
      state.isAuthenticating = false;
      state.newPasswordRequired = false;
      state.verificationCodeRequested = false;
    },
    resetVerificationCodeRequested(state) {
      state.verificationCodeRequested = false;
    }
  },

  // async reducers
  extraReducers: {
    // check current session (is user currently logged in?)
    [checkCurrentSession.pending]: (state) => {
      state.isAuthenticating = true;
      state.isAuthenticated = false;
    },
    [checkCurrentSession.fulfilled]: (state) => {
      state.isAuthenticating = false;
      state.isAuthenticated = true;
    },
    [checkCurrentSession.rejected]: (state, action) => {
      state.isAuthenticating = false;
      // "No current user" means not currently authenticated
      if (action.error.message !== "No current user") {
        onError(action.error.message);
      }
    },

    // change password
    [changePassword.pending]: (state) => {
      state.isAuthenticating = true;
    },
    [changePassword.fulfilled]: (state) => {
      state.isAuthenticating = false;
      alertInfo("Your password has been changed.");
    },
    [changePassword.rejected]: (state, action) => {
      state.isAuthenticating = false;
      onError(action.error.message);
    },
    [completeNewPassword.pending]: (state) => {
      state.isAuthenticating = true;
      state.isAuthenticated = false;
    },
    [completeNewPassword.fulfilled]: (state) => {
      state.isAuthenticating = false;
      state.isAuthenticated = true;
      state.newPasswordRequired = false;
    },
    [completeNewPassword.rejected]: (state, action) => {
      state.isAuthenticating = false;
      state.newPasswordRequired = false;
      onError(action.error);
    },

    // forgot password
    [forgotPassword.pending]: (state) => {
      state.isAuthenticating = true;
      state.verificationCodeRequested = false;
    },
    [forgotPassword.fulfilled]: (state) => {
      state.isAuthenticating = false;
      state.verificationCodeRequested = true;
    },
    [forgotPassword.rejected]: (state, action) => {
      state.isAuthenticating = false;
      onError(action.error);
    },
    [forgotPasswordComplete.pending]: (state) => {
      state.isAuthenticating = true;
    },
    [forgotPasswordComplete.fulfilled]: (state) => {
      state.isAuthenticating = false;
      alertInfo(
        "Your password has been reset. You may now login with your new credentials."
      );
      state.verificationCodeRequested = false;
    },
    [forgotPasswordComplete.rejected]: (state, action) => {
      state.isAuthenticating = false;
      onError(action.error);
    },

    // login
    [login.pending]: (state) => {
      state.isAuthenticating = true;
      state.isAuthenticated = false;
      state.newPasswordRequired = false;
    },
    [login.fulfilled]: (state, action) => {
      state.isAuthenticating = false;
      if (action.payload === USER_CHALLENGE_NEW_PASSWORD_REQUIRED) {
        alertInfo("You have used a temporary password. Please create a new one.");
        state.newPasswordRequired = true;
      } else {
        state.isAuthenticated = true;
      }
    },
    [login.rejected]: (state, action) => {
      state.isAuthenticating = false;
      onError(action.error);
    },

    // logout
    [logout.pending]: (state) => {
      state.isAuthenticating = true;
      state.isAuthenticated = false;
      state.newPasswordRequired = false;
      state.verificationCodeRequested = false;
    },
    [logout.fulfilled]: (state) => {
      state.isAuthenticating = false;
    },
    [logout.rejected]: (state, action) => {
      state.isAuthenticating = false;
      onError(action.error);
    },

    // signup
    [signUp.pending]: (state) => {
      state.isAuthenticating = true;
      state.verificationCodeRequested = false;
    },
    [signUp.fulfilled]: (state) => {
      state.isAuthenticating = false;
      state.verificationCodeRequested = true;
    },
    [signUp.rejected]: (state, action) => {
      state.isAuthenticating = false;
      onError(action.error);
    },
    [signUpConfirm.pending]: (state) => {
      state.isAuthenticating = true;
    },
    [signUpConfirm.fulfilled]: (state) => {
      state.isAuthenticating = false;
      alertInfo(
        "Signup is complete. You may now login with your new credentials."
      );
      state.verificationCodeRequested = false;
    },
    [signUpConfirm.rejected]: (state, action) => {
      state.isAuthenticating = false;
      onError(action.error);
    },
  },
});

export const { resetAuthState, resetVerificationCodeRequested } = authSlice.actions;
export const selectIsAuthenticated = ({ auth }) => auth.isAuthenticated;
export const selectIsAuthenticating = ({ auth }) => auth.isAuthenticating;
export const selectNewPasswordRequired = ({ auth }) => auth.newPasswordRequired;
export const selectVerificationCodeRequested = ({ auth }) =>
  auth.verificationCodeRequested;
export default authSlice.reducer;
