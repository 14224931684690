import "./Login.css";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/Form";
import LoaderButton from "../../components/LoaderButton";
import Loader from "../../components/Loader";
import { useFormFields } from "../../libs/hooks-lib";
import {
  login,
  selectIsAuthenticating,
  selectNewPasswordRequired,
} from "./authSlice";

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticating = useSelector(selectIsAuthenticating);
  const newPasswordRequired = useSelector(selectNewPasswordRequired);

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (fields.email && newPasswordRequired) {
      history.push(`/login/new?email=${encodeURIComponent(fields.email)}`);
    }
  }, [history, fields.email, newPasswordRequired]);

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();

    const args = {
      username: fields.email,
      password: fields.password,
    };
    dispatch(login(args));
  }

  return (
    <Col md={8} lg={6} className="m-auto py-3">
      {isAuthenticating ? (
        <Loader />
      ) : (
        <Card>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group size="lg" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  autoFocus
                  type="email"
                  value={fields.email}
                  disabled={isAuthenticating}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group size="lg" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  autoComplete="on"
                  value={fields.password}
                  disabled={isAuthenticating}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                isLoading={isAuthenticating}
                disabled={!validateForm()}
              >
                Login
              </LoaderButton>
            </Form>
            <div className="mt-2">
              <Link to="/login/forgot">Forgot password?</Link>
            </div>
          </Card.Body>
        </Card>
      )}
    </Col>
  );
}
