import React from "react";
import { Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./features/auth/AuthenticatedRoute";
import UnauthenticatedRoute from "./features/auth/UnauthenticatedRoute";
import Login from "./features/auth/Login";
import LoginForgot from "./features/auth/LoginForgot";
import LoginForgotVerify from "./features/auth/LoginForgotVerify";
import LoginNew from "./features/auth/LoginNew";
import Main from "./containers/Main";
import NotFound from "./containers/NotFound";
import Signup from "./features/auth/Signup";
import SignupVerify from "./features/auth/SignupVerify";
import Settings from "./containers/Settings";



export default function Routes() {
  return (
    <Switch>
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/login/new">
        <LoginNew />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/login/forgot">
        <LoginForgot />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/login/forgot/verify">
        <LoginForgotVerify />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/signup">
        <Signup />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/signup/verify">
        <SignupVerify />
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/settings">
        <Settings />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/:activeTab?">
        <Main />
      </AuthenticatedRoute>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
