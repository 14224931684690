import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import eventsDataReducer from "../features/driveEvents/eventsDataSlice";
import queryControlsReducer from "../features/queryBuilder/queryControlsSlice";
import scenariosReducer from "../features/scenarioResults/scenariosSlice";
import annotationReducer from "../features/annotationService/annotationSlice";
import statsReducer from "../features/stats/statsSlice";
import loggerMiddleware from "./middleware/logger";
// import monitorReducerEnhancer from "./enhancers/monitorReducer";

const middleware = getDefaultMiddleware();
middleware.push(loggerMiddleware);
const enhancers = [];
// enhancers.push(monitorReducerEnhancer);

export default configureStore({
  reducer: {
    auth: authReducer,
    eventsData: eventsDataReducer,
    queryControls: queryControlsReducer,
    scenarios: scenariosReducer,
    stats: statsReducer,
    annotation: annotationReducer
  },
  middleware,
  enhancers,
});
