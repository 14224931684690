import "./App.css";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "./libs/context-lib";
import Routes from "./Routes";
import {
  checkCurrentSession,
  logout,
  resetAuthState,
  selectIsAuthenticated,
} from "./features/auth/authSlice";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";

function App(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const eventsTable = props.eventsTable;
  const isAuthenticated = useSelector(selectIsAuthenticated);

  useEffect(() => {
    dispatch(checkCurrentSession());
  }, [dispatch]);

  async function handleLogout() {
    await dispatch(logout());
    history.push("/login");
  }

  return (
    <React.Fragment>
      <Navbar
        collapseOnSelect
        sticky="top"
        expand="lg"
        bg="dark"
        variant="dark"
      >
        <Link to="/" className="navbar-brand">
          <img alt="Nemo" src="/nemo-transparent.png" height="27" width="97" />
        </Link>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav activeKey={window.location.pathname}>
            {isAuthenticated ? (
              <React.Fragment>
                <Link to="/settings" className="nav-link">
                  Settings
                </Link>
                <Link to="#" className="nav-link" onClick={handleLogout}>
                  Logout
                </Link>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {/* <Link to="/signup" className="nav-link">Signup</Link> */}
                <Link
                  to="/login"
                  className="nav-link"
                  onClick={() => dispatch(resetAuthState())}
                >
                  Login
                </Link>
              </React.Fragment>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <AppContext.Provider value={{ eventsTable }}>
        <Container fluid>
          <Row>
            <Routes />
          </Row>
        </Container>
      </AppContext.Provider>
    </React.Fragment>
  );
}

export default App;
