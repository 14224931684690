import React from "react";
import Plot from "react-plotly.js";
import proj4 from "proj4";

const layout = {
  showlegend: false,
  yaxis: {
    scaleanchor: "x",
    scaleratio: 1,
  },
  margin: {
    l: 0,
    r: 0,
    b: 0,
    t: 0,
    pad: 4,
  },
  autosize: true,
};

export default class ObjectTracksChart extends React.PureComponent {
  renderLoading() {
    return (
      <div className="py-5 text-center">
        <div className="spinner-border text-secondary">
          <span className="sr-only">Loading…</span>
        </div>
      </div>
    );
  }

  renderNoData() {
    return (
      <div className="py-5 text-center">
        <h4>No data available</h4>
      </div>
    );
  }

  renderPlotError() {
    return (
      <div className="py-5 text-center">
        <h5 className="text-danger">Error rendering plot</h5>
        <p className="text-danger">See the developer console for details</p>
      </div>
    );
  }

  renderPlot() {
    const { data, tStart, tEnd, mapInfo } = this.props;
    const tScale = 1 / (tEnd - tStart);
    const opacity = data.t.map((t) => tScale * (t - tStart));
    // Convert object tracks to <lat, lon> (TODO: move origin to config file)
    const map_origin = mapInfo["map_origin"], map_utm_zone = mapInfo["map_utm_zone"];
    const utm = `+proj=utm +zone=${map_utm_zone[0]}`; // UTM
    const wgs84 = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs"; // LLA
    var x = data["pose.position.x"],
      y = data["pose.position.y"];
    // console.log("ObjectTracksChart.renderPlot(): x:", x[0]);  // [debug]
    // console.log("ObjectTracksChart.renderPlot(): y:", y[0]);  // [debug]
    var lat = Array(x.length),
      lon = Array(x.length);
    for (let i = 0; i < x.length; i++) {
      var lon_lat = proj4(utm, wgs84, [
        x[i] + map_origin[0],
        y[i] + map_origin[1],
      ]);
      lon[i] = lon_lat[0]; // note order of lon, lat
      lat[i] = lon_lat[1];
    }
    // console.debug("ObjectTracksChart.renderPlot(): lat:", lat[0]);  // [debug]
    // console.debug("ObjectTracksChart.renderPlot(): lon:", lon[0]);  // [debug]
    // Zoom to map data extent (plotly/mapbox doesn't have great support for auto-zoom yet)
    // See: https://github.com/plotly/plotly.js/issues/5296
    var lat_range = [Math.min(...lat), Math.max(...lat)];
    var lon_range = [Math.min(...lon), Math.max(...lon)];
    var center = {
      lat: (lat_range[0] + lat_range[1]) / 2.0,
      lon: (lon_range[0] + lon_range[1]) / 2.0,
    };
    var zoom_factor = Math.max(
      ...[
        Math.abs(lat_range[1] - lat_range[0]),
        Math.abs(lon_range[1] - lon_range[0]),
      ],
      0.002
    );
    var auto_zoom = Math.round(
      Math.abs(-1.35 * Math.log(zoom_factor) + 7) + 0.5
    ); // round up
    // console.debug(`ObjectTracksChart.renderPlot(): zoom_factor: ${zoom_factor}, auto_zoom: ${auto_zoom}`);  // [debug]
    // Choose plotly mapbox style, set center and zoom level
    // - sans token: open-street-map, white-bg, carto-positron, carto-darkmatter, stamen-terrain, stamen-toner, stamen-watercolor
    // - with token: basic, streets, outdoors, light, dark, satellite, satellite-streets
    var mapbox_layout = {
      mapbox: {
        style: "carto-positron",
        center: center,
        zoom: auto_zoom,
      },
    };
    return (
      <Plot
        className="d-block"
        data={[
          {
            type: "scattermapbox",
            mode: "markers",
            lat: lat,
            lon: lon,
            meta: data.object_id,
            customdata: data.t.map(function (v, i) {
              return [v, x[i], y[i]]; // [t0, ...] -> [[t0, x0, y0], ...]
            }),
            transforms: [{ type: "groupby", groups: data.object_type }],
            marker: { opacity },
            hoverinfo: data.object_type,
            hovertemplate:
              "<b>id: %{meta}</b><br>" +
              "lat: %{lat:.3f}<br>" +
              "lon: %{lon:.3f}<br>" +
              "t: %{customdata[0]:.2f}<br>" +
              "x: %{customdata[1]:.2f}<br>" +
              "y: %{customdata[2]:.2f}",
          },
        ]}
        layout={{ ...layout, ...mapbox_layout }}
        config={{ responsive: true }}
      />
    );
  }

  render() {
    if (this.props.isLoading) return this.renderLoading();
    if (!this.props.data) return this.renderNoData();
    try {
      return this.renderPlot();
    } catch (e) {
      console.error(e);
      return this.renderPlotError(String(e));
    }
  }
}
