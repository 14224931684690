import React from "react";
import Plot from "react-plotly.js";

const defaultTrace = {
  x: [],
  y: [],
  name: "",
  orientation: "h",
  type: "bar",
};

export default function BarChart(props) {
  const layout = {
    height: 290,
    autosize: true,
    margin: {
      t: 10,
      l: 30,
      r: 30,
      b: 20,
    },
    yaxis: {
      type: "log",
      autorange: true,
    },
    xaxis: {
      showticklabels: false,
    },
    legend: {
      orientation: "h",
    },
    bargap: 0.2,
  };

  if (props.data == null || props.data.length === 0) {
    return (
      <Plot
        className="d-block plot-disabled"
        data={[defaultTrace]}
        layout={layout}
      />
    );
  }

  const colorMap = {
    camera2d_objects: "#28a745",
    ego: "#dc3545",
    interaction: "#ffc107",
    map_track: "#17a2b8",
    objects: "#343a40",
  };

  const traces = Object.keys(props.data).map((category) => ({
    x: Object.keys(props.data[category]),
    y: Object.values(props.data[category]),
    name: category,
    type: "bar",
    marker: { color: colorMap[category] },
  }));

  return (
    <Plot
      className={`d-block${props.isLoading ? " plot-disabled" : ""}`}
      data={traces}
      layout={layout}
      config={{ responsive: true }}
    />
  );
}
