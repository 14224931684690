import React from "react";
import { useSelector } from "react-redux";

import { selectEventsServiceStatus } from "./eventsDataSlice";
import ProgressDonut from "../../components/ProgressDonut";

export default function DataProgress() {
  const eventsServiceStatus = useSelector(selectEventsServiceStatus);

  return (
    <ProgressDonut
      className="ml-2"
      style={{ opacity: eventsServiceStatus.initializing ? 1 : 0.5 }}
      size={1.25}
      strokeWidth={5}
      initialOffset={12.5}
      successCount={eventsServiceStatus.ready}
      errorCount={eventsServiceStatus.error}
      totalCount={eventsServiceStatus.total}
    />
  );
}
