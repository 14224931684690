import { localDate } from "../../libs/date-utils";
import { mapInfo } from "./mapInfo";

export const tripInfo = [
  // 2020-09-30
  {
    id: "42a8548c-b0ed-58e8-8ebd-7038059682ff",
    start_time: "2020-09-30 16:17:04.817292-07:00",
    end_time: "2020-09-30 16:29:40.262117-07:00",
    start_location: null,
    end_location: null,
    metadata: {
      drive_dir: "Grape/transfer_2/2020_09_30_triage/route1/run1",
      map_id: "auro-site-milpitas"
    },
  },
  {
    id: "ed680151-5b74-5950-84c0-98056e7941c6",
    start_time: "2020-09-30 16:30:16.859015-07:00",
    end_time: "2020-09-30 16:34:07.931325-07:00",
    start_location: null,
    end_location: null,
    metadata: {
      drive_dir: "Grape/transfer_2/2020_09_30_triage/route1/run2_m",
      map_id: "auro-site-milpitas"
    },
  },
  {
    id: "581b8bd6-2b05-5612-82bb-42376f3a095d",
    start_time: "2020-09-30 16:49:02.053759-07:00",
    end_time: "2020-09-30 16:58:56.638739-07:00",
    start_location: null,
    end_location: null,
    metadata: {
      drive_dir: "Grape/transfer_2/2020_09_30_triage/route1/run3_m",
      map_id: "auro-site-milpitas"
    },
  },
  {
    id: "f1fd3527-cb7b-5355-ace5-b32a14c015e7",
    start_time: "2020-09-30 17:02:42.935785-07:00",
    end_time: "2020-09-30 17:15:39.138735-07:00",
    start_location: null,
    end_location: null,
    metadata: {
      drive_dir: "Grape/transfer_2/2020_09_30_triage/residential/run1",
      map_id: "auro-site-milpitas"
    },
  },
  {
    id: "44c00963-58d7-57b4-a311-990f535b58ec",
    start_time: "2020-09-30 17:15:52.796597-07:00",
    end_time: "2020-09-30 17:33:08.308485-07:00",
    start_location: null,
    end_location: null,
    metadata: {
      drive_dir: "Grape/transfer_2/2020_09_30_triage/residential/run2_m",
      map_id: "auro-site-milpitas"
    },
  },

  // 2020-10-27
  {
    id: "6d269679-c5d4-574b-8aa2-a34ddd6a4d64",
    start_time: "2020-10-27 11:16:33.468843-07:00",
    end_time: "2020-10-27 12:58:31.939836-07:00",
    start_location: null,
    end_location: null,
    metadata: {
      drive_dir: "Grape/transfer_1/2020_10_27_manual_jeep_scenario_new_route_1/run1",
      map_id: "auro-site-milpitas"
    },
  },

  // 2021-01-12
  {
    id: "c1f2e427-2290-5342-8585-9ff9c3b4430c",
    start_time: "2021-01-12 12:02:27.064533-08:00",
    end_time: "2021-01-12 12:33:26.567102-08:00",
    start_location: { x: 159.00128615521976, y: -733.1648536505661 },
    end_location: { x: -69.39298589343014, y: -664.4264286450474 },
    metadata: {
      drive_dir: "2021_01_12_manual_data_collection_route1_run1",
      map_id: "auro-site-milpitas"
    },
  },
  {
    id: "45d0e10e-1fe8-56d3-a3cc-0fd370fc7b8d",
    start_time: "2021-01-12 12:38:22.331022-08:00",
    end_time: "2021-01-12 12:59:25.462501-08:00",
    start_location: { x: -69.45124717415861, y: -664.3949020174001 },
    end_location: { x: -40.61186748193391, y: -744.2607298532652 },
    metadata: {
      drive_dir: "2021_01_12_manual_data_collection_route1_run2",
      map_id: "auro-site-milpitas"
    },
  },
  {
    id: "484b37ba-942f-55f9-854c-86f8f3e9b25c",
    start_time: "2021-01-12 13:12:11.417352-08:00",
    end_time: "2021-01-12 13:43:00.528178-08:00",
    start_location: null,
    end_location: null,
    metadata: {
      drive_dir: "2021_01_12_manual_data_collection_residential_run1",
      map_id: "auro-site-milpitas",
      canbus: { vehicle_id: "3FA6P0RU5JR110979" }
    },
  },
  {
    id: "789f2945-0fcf-59b8-8078-d2857a4afcd3",
    start_time: "2021-01-12 13:43:45.547513-08:00",
    end_time: "2021-01-12 13:51:06.878231-08:00",
    start_location: { x: 768.1145169152759, y: -249.36107678445686 },
    end_location: { x: -31.223091267656315, y: -14.767007510474112 },
    metadata: {
      drive_dir: "2021_01_12_manual_data_collection_tasman_run1",
      map_id: "auro-site-milpitas"
    },
  },

  // 2021-01-29
  {
    id: "dd54f11b-3804-5651-9027-a87eb0768a2c",
    start_time: "2021-01-29 13:36:26.748118-08:00",
    end_time: "2021-01-29 13:40:49.039392-08:00",
    start_location: { x: -51.70839553314575, y: 2270.2946076309368 },
    end_location: { x: 131.92907118564474, y: 1462.375504681834 },
    metadata: {
      drive_dir: "2021_01_29_berkeley_slam_run1",
      map_id: "auro-site-berkeley"
    },
  },
  {
    id: "e499a051-70b9-58d1-a8de-574bae2a0d5a",
    start_time: "2021-01-29 13:42:42.969893-08:00",
    end_time: "2021-01-29 14:04:45.451616-08:00",
    start_location: { x: 122.53692937443437, y: 1612.159619985512 },
    end_location: { x: -61.09736930403977, y: 2269.460866637861 },
    metadata: {
      drive_dir: "2021_01_29_berkeley_slam_run2",
      map_id: "auro-site-berkeley"
    },
  },
  {
    id: "63cd784b-4891-5842-93c4-981bbb7f2770",
    start_time: "2021-01-29 14:39:56.465327-08:00",
    end_time: "2021-01-29 15:03:39.889865-08:00",
    start_location: { x: 319.33849766760375, y: 1240.3183065326207 },
    end_location: { x: 334.8346777737311, y: 1191.564540529221 },
    metadata: {
      drive_dir: "2021_01_29_berkeley_slam_run3",
      map_id: "auro-site-berkeley"
    },
  },
  {
    id: "df6fd3ee-5f50-5e20-9581-21f8bb53a3fc",
    start_time: "2021-01-29 15:06:18.197680-08:00",
    end_time: "2021-01-29 15:28:14.031403-08:00",
    start_location: { x: -268.58590291437486, y: 1126.105556137661 },
    end_location: { x: -343.56376878870714, y: 1429.973047922852 },
    metadata: {
      drive_dir: "2021_01_29_berkeley_slam_run4",
      map_id: "auro-site-berkeley"
    },
  },
  {
    id: "3259bef0-e54a-506c-8cdc-c0f70c3cc7a4",
    start_time: "2021-01-29 15:42:58.314045-08:00",
    end_time: "2021-01-29 16:03:49.411392-08:00",
    start_location: { x: 216.4943058696028, y: 18.268705670084966 },
    end_location: { x: 1627.0704820550984, y: 301.64455587027817 },
    metadata: {
      drive_dir: "2021_01_29_berkeley_slam_run5",
      map_id: "auro-site-berkeley"
    },
  },
  {
    id: "233f8870-b5c2-548b-9bf1-ff7f54990f5c",
    start_time: "2021-01-29 16:06:08.652333-08:00",
    end_time: "2021-01-29 16:31:20.710108-08:00",
    start_location: { x: 1718.2703559639497, y: 121.82976716300607 },
    end_location: { x: 94.53813756340082, y: -10.014841927578445 },
    metadata: {
      drive_dir: "2021_01_29_berkeley_slam_run6",
      map_id: "auro-site-berkeley"
    },
  },

  // {
  //   id: "42a8548c-b0ed-58e8-8ebd-7038059682ff",
  //   start_time: "2020-09-30T23:17:04.8Z",
  //   end_time: "2020-09-30T23:29:39.9Z",
  // },
  // {
  //   id: "c1a23127-3da2-4d86-8cd2-6b9523ae7f0f",
  //   start_time: "2020-09-29T23:17:04.8Z",
  //   end_time: "2020-09-29T23:29:39.9Z",
  // },
  // {
  //   id: "2693a1f4-9d5e-493f-bf46-f312245a4511",
  //   start_time: "2020-09-28T23:17:04.8Z",
  //   end_time: "2020-09-28T23:29:39.9Z",
  // },
  // {
  //   id: "69c4e701-2300-4926-ad4d-4ea16894d59c",
  //   start_time: "2020-09-27T23:17:04.8Z",
  //   end_time: "2020-09-27T23:29:39.9Z",
  // },
  // {
  //   id: "1c2507f8-73d5-4291-b63b-25e4d6ec3f59",
  //   start_time: "2020-09-26T23:17:04.8Z",
  //   end_time: "2020-09-26T23:29:39.9Z",
  // },
  // {
  //   id: "1c4fa1e8-efbe-4082-82c9-c63aa71ff2b4",
  //   start_time: "2020-09-25T23:17:04.8Z",
  //   end_time: "2020-09-25T23:29:39.9Z",
  // },
  // {
  //   id: "e20cb44c-7248-4fd8-b1d1-7cd8cb75e3d3",
  //   start_time: "2020-09-24T23:17:04.8Z",
  //   end_time: "2020-09-24T23:29:39.9Z",
  // },
  // {
  //   id: "7595e33f-9bf9-44d4-9021-2d62538476a9",
  //   start_time: "2020-09-23T23:17:04.8Z",
  //   end_time: "2020-09-23T23:29:39.9Z",
  // },
  // {
  //   id: "0ffba390-2d13-4dc7-b686-4faf0b1a86cb",
  //   start_time: "2020-09-22T23:17:04.8Z",
  //   end_time: "2020-09-22T23:29:39.9Z",
  // },
  // {
  //   id: "ddc22bb8-24da-424e-991f-1143dcb7ccf3",
  //   start_time: "2020-09-21T23:17:04.8Z",
  //   end_time: "2020-09-21T23:29:39.9Z",
  // },
  // {
  //   id: "bcef0f81-a247-4e30-bdd5-18e8d6fde115",
  //   start_time: "2020-09-20T23:17:04.8Z",
  //   end_time: "2020-09-20T23:29:39.9Z",
  // },
  // {
  //   id: "5e73972e-eaa9-440a-96c7-744b86d3cb06",
  //   start_time: "2020-09-19T23:17:04.8Z",
  //   end_time: "2020-09-19T23:29:39.9Z",
  // },
  // {
  //   id: "4caefa20-cf5f-4c0f-828f-f0ec6a7904cZ",
  //   start_time: "2020-09-18T23:17:04.8Z",
  //   end_time: "2020-09-18T23:29:39.9Z",
  // },
  // {
  //   id: "65e038a7-2bfe-422a-9538-f446ae427f34",
  //   start_time: "2020-09-17T23:17:04.8Z",
  //   end_time: "2020-09-17T23:29:39.9Z",
  // },
  // {
  //   id: "fc25437a-ab36-4d61-84c5-604750e35d31",
  //   start_time: "2020-09-16T23:17:04.8Z",
  //   end_time: "2020-09-16T23:29:39.9Z",
  // },
  // {
  //   id: "6844808e-d85e-4731-bde7-644dfd1d489Z",
  //   start_time: "2020-09-15T23:17:04.8Z",
  //   end_time: "2020-09-15T23:29:39.9Z",
  // },
  // {
  //   id: "a03dc4cf-00ff-4640-af7d-941b0bcbbddd",
  //   start_time: "2020-09-14T23:17:04.8Z",
  //   end_time: "2020-09-14T23:29:39.9Z",
  // },
  // {
  //   id: "705bb021-54a0-4410-b3b0-225a505b5278",
  //   start_time: "2020-09-13T23:17:04.8Z",
  //   end_time: "2020-09-13T23:29:39.9Z",
  // },
  // {
  //   id: "441b2c74-16d2-48ff-9086-e4bd98c6d883",
  //   start_time: "2020-09-12T23:17:04.8Z",
  //   end_time: "2020-09-12T23:29:39.9Z",
  // },
  // {
  //   id: "d0b7d1b3-3f44-4536-884e-d1b67c4644c6",
  //   start_time: "2020-09-11T23:17:04.8Z",
  //   end_time: "2020-09-11T23:29:39.9Z",
  // },

  // {
  //   id: "44c00963-58d7-57b4-a311-990f535b58ec",
  //   start_time: "2020-10-01T00:15:52.796Z",
  //   end_time: "2020-10-01T00:33:08.3Z",
  // },
  // {
  //   id: "cc153902-311e-4012-8338-07835cebb53b",
  //   start_time: "2020-10-02T00:15:52.796Z",
  //   end_time: "2020-10-02T00:33:08.3Z",
  // },
  // {
  //   id: "0ea436fc-121a-40e6-833d-f6cc5f9379b2",
  //   start_time: "2020-10-03T00:15:52.796Z",
  //   end_time: "2020-10-03T00:33:08.3Z",
  // },
  // {
  //   id: "b75ff0b0-b0d1-4738-a9ef-d265ba370ac2",
  //   start_time: "2020-10-04T00:15:52.796Z",
  //   end_time: "2020-10-04T00:33:08.3Z",
  // },
  // {
  //   id: "9f4a3832-6104-4e3f-9940-139be8af95f8",
  //   start_time: "2020-10-05T00:15:52.796Z",
  //   end_time: "2020-10-05T00:33:08.3Z",
  // },
  // {
  //   id: "2757b95b-642a-4e24-803b-f593f9e5e71c",
  //   start_time: "2020-10-06T00:15:52.796Z",
  //   end_time: "2020-10-06T00:33:08.3Z",
  // },
  // {
  //   id: "008fade9-56a8-4377-95ed-74316a75bdc3",
  //   start_time: "2020-10-07T00:15:52.796Z",
  //   end_time: "2020-10-07T00:33:08.3Z",
  // },
  // {
  //   id: "c9481f13-5693-455c-bf18-147e98ef2261",
  //   start_time: "2020-10-08T00:15:52.796Z",
  //   end_time: "2020-10-08T00:33:08.3Z",
  // },
  // {
  //   id: "d3d3c39c-9439-4162-b3e7-b7732a55c379",
  //   start_time: "2020-10-09T00:15:52.796Z",
  //   end_time: "2020-10-09T00:33:08.3Z",
  // },
  // {
  //   id: "b4662a23-3490-477c-8ea7-a08945e67633",
  //   start_time: "2020-10-10T00:15:52.796Z",
  //   end_time: "2020-10-10T00:33:08.3Z",
  // },
  // {
  //   id: "0c35b5f9-f7f2-44c3-9f70-0080f3ed4623",
  //   start_time: "2020-10-11T00:15:52.796Z",
  //   end_time: "2020-10-11T00:33:08.3Z",
  // },
  // {
  //   id: "591dabef-2a25-4160-b268-f5746f3ebd9d",
  //   start_time: "2020-10-12T00:15:52.796Z",
  //   end_time: "2020-10-12T00:33:08.3Z",
  // },
  // {
  //   id: "00126335-8487-41a6-8c03-f4251e6b4d37",
  //   start_time: "2020-10-13T00:15:52.796Z",
  //   end_time: "2020-10-13T00:33:08.3Z",
  // },
  // {
  //   id: "cffc4b4f-9df8-4f21-a802-99621e76d081",
  //   start_time: "2020-10-14T00:15:52.796Z",
  //   end_time: "2020-10-14T00:33:08.3Z",
  // },
  // {
  //   id: "13948589-3dee-451b-aa49-2dc8256e45da",
  //   start_time: "2020-10-15T00:15:52.796Z",
  //   end_time: "2020-10-15T00:33:08.3Z",
  // },
  // {
  //   id: "9b82db91-1766-40ee-99d5-bb8b6fd217c5",
  //   start_time: "2020-10-16T00:15:52.796Z",
  //   end_time: "2020-10-16T00:33:08.3Z",
  // },
  // {
  //   id: "ae22c3a5-2a64-4dc5-9934-b864b9ec7a74",
  //   start_time: "2020-10-17T00:15:52.796Z",
  //   end_time: "2020-10-17T00:33:08.3Z",
  // },
  // {
  //   id: "17ac74ea-7fc3-433e-b0fe-f6dcb2739c65",
  //   start_time: "2020-10-18T00:15:52.796Z",
  //   end_time: "2020-10-18T00:33:08.3Z",
  // },
  // {
  //   id: "97617576-e732-43bd-a838-ab56a78fc8c2",
  //   start_time: "2020-10-19T00:15:52.796Z",
  //   end_time: "2020-10-19T00:33:08.3Z",
  // },
  // {
  //   id: "1ae6d749-079b-45a8-8a6b-549527c98f47",
  //   start_time: "2020-10-20T00:15:52.796Z",
  //   end_time: "2020-10-20T00:33:08.3Z",
  // },
  // {
  //   id: "094781ca-9a4c-45b1-bb5f-21a7655706db",
  //   start_time: "2020-10-21T00:15:52.796Z",
  //   end_time: "2020-10-21T00:33:08.3Z",
  // },

  // {
  //   id: "581b8bd6-2b05-5612-82bb-42376f3a095d",
  //   start_time: "2020-09-30T23:49:02.053Z",
  //   end_time: "2020-09-30T23:58:56.7Z",
  // },
  // {
  //   id: "206f3848-b5e2-4171-8f49-f0eb4ad60f7a",
  //   start_time: "2020-09-30T23:49:02.053Z",
  //   end_time: "2020-09-30T23:58:56.9Z",
  // },
  // {
  //   id: "adfd2cab-8eee-4090-a00a-63916acbe4ef",
  //   start_time: "2020-10-01T23:49:02.053Z",
  //   end_time: "2020-10-01T23:58:56.9Z",
  // },
  // {
  //   id: "0c743e18-5cb0-4e0c-baf4-240ad7ca7f1a",
  //   start_time: "2020-10-02T23:49:02.053Z",
  //   end_time: "2020-10-02T23:58:56.9Z",
  // },
  // {
  //   id: "569534aa-8322-420f-90fb-b8a8b2c307e6",
  //   start_time: "2020-10-03T23:49:02.053Z",
  //   end_time: "2020-10-03T23:58:56.9Z",
  // },
  // {
  //   id: "ebc2f2ef-39a0-4c26-98d6-ccf5fe7b7b2Z",
  //   start_time: "2020-10-04T23:49:02.053Z",
  //   end_time: "2020-10-04T23:58:56.9Z",
  // },
  // {
  //   id: "94811394-5741-4bc0-a3d4-8aed86419487",
  //   start_time: "2020-10-05T23:49:02.053Z",
  //   end_time: "2020-10-05T23:58:56.9Z",
  // },
  // {
  //   id: "6e05db64-d4ec-4ece-910d-0c9717c9e488",
  //   start_time: "2020-10-06T23:49:02.053Z",
  //   end_time: "2020-10-06T23:58:56.9Z",
  // },
  // {
  //   id: "ed12af87-5fab-4829-a058-c9e6f7c1e116",
  //   start_time: "2020-10-07T23:49:02.053Z",
  //   end_time: "2020-10-07T23:58:56.9Z",
  // },
  // {
  //   id: "1f8a3eae-cefd-4d18-94b7-1724e4d5e622",
  //   start_time: "2020-10-08T23:49:02.053Z",
  //   end_time: "2020-10-08T23:58:56.9Z",
  // },
  // {
  //   id: "3f80bdd6-428a-4e0a-a8b1-e1b93648c282",
  //   start_time: "2020-10-09T23:49:02.053Z",
  //   end_time: "2020-10-09T23:58:56.9Z",
  // },
  // {
  //   id: "1e460346-5c28-487f-a19f-660f64072b41",
  //   start_time: "2020-10-10T23:49:02.053Z",
  //   end_time: "2020-10-10T23:58:56.9Z",
  // },
  // {
  //   id: "948e3c2d-e00b-4ded-968d-1cc7ad7ef1e1",
  //   start_time: "2020-10-11T23:49:02.053Z",
  //   end_time: "2020-10-11T23:58:56.9Z",
  // },
  // {
  //   id: "890ccc7c-f248-47b6-bffb-0cbd9cb31a8a",
  //   start_time: "2020-10-12T23:49:02.053Z",
  //   end_time: "2020-10-12T23:58:56.9Z",
  // },
  // {
  //   id: "94374faa-7573-442e-8470-68056a67039c",
  //   start_time: "2020-10-13T23:49:02.053Z",
  //   end_time: "2020-10-13T23:58:56.9Z",
  // },
  // {
  //   id: "f572035f-9563-4c0e-b811-eeb41c2e16dd",
  //   start_time: "2020-10-14T23:49:02.053Z",
  //   end_time: "2020-10-14T23:58:56.9Z",
  // },
  // {
  //   id: "254db0d3-edc8-44d6-953e-7596c3e26566",
  //   start_time: "2020-10-15T23:49:02.053Z",
  //   end_time: "2020-10-15T23:58:56.9Z",
  // },
  // {
  //   id: "a8026f86-7ea2-43a0-8a0f-c6a7cab2e4ef",
  //   start_time: "2020-10-16T23:49:02.053Z",
  //   end_time: "2020-10-16T23:58:56.9Z",
  // },
  // {
  //   id: "45ba43a1-79ef-496c-99d2-4fec5a173d75",
  //   start_time: "2020-10-17T23:49:02.053Z",
  //   end_time: "2020-10-17T23:58:56.9Z",
  // },
  // {
  //   id: "c178bb69-ffc7-4281-8741-0fb524190612",
  //   start_time: "2020-10-18T23:49:02.053Z",
  //   end_time: "2020-10-18T23:58:56.9Z",
  // },
  // {
  //   id: "75672084-80bd-4493-aff8-ff348874848f",
  //   start_time: "2020-10-19T23:49:02.053Z",
  //   end_time: "2020-10-19T23:58:56.9Z",
  // },

  // {
  //   id: "6d269679-c5d4-574b-8aa2-a34ddd6a4d64",
  //   start_time: "2020-10-27T18:16:33.468Z",
  //   end_time: "2020-10-27T19:58:31.9Z",
  // },
  // {
  //   id: "74dd2e7d-c903-4bdf-94d7-cb3d8e46810a",
  //   start_time: "2020-10-25T18:16:33.468Z",
  //   end_time: "2020-10-25T19:58:31.9Z",
  // },
  // {
  //   id: "b4460d52-375d-4edc-8a6d-a117d1e09d17",
  //   start_time: "2020-10-23T18:16:33.468Z",
  //   end_time: "2020-10-23T19:58:31.9Z",
  // },
  // {
  //   id: "42c0cc73-0573-4771-8ffe-d8539d26d8e9",
  //   start_time: "2020-10-21T18:16:33.468Z",
  //   end_time: "2020-10-21T19:58:31.9Z",
  // },
  // {
  //   id: "e65f6c82-b8bd-4ce8-9643-cc9940372ab2",
  //   start_time: "2020-10-19T18:16:33.468Z",
  //   end_time: "2020-10-19T19:58:31.9Z",
  // },
  // {
  //   id: "7582bb59-e5c6-4e1e-aeb8-eff073bdf945",
  //   start_time: "2020-10-17T18:16:33.468Z",
  //   end_time: "2020-10-17T19:58:31.9Z",
  // },
  // {
  //   id: "3cf37919-8ad2-46fa-bcc6-714abd174d15",
  //   start_time: "2020-10-15T18:16:33.468Z",
  //   end_time: "2020-10-15T19:58:31.9Z",
  // },
  // {
  //   id: "0a9ed7bf-f4bd-4423-931e-cf1985716c91",
  //   start_time: "2020-10-13T18:16:33.468Z",
  //   end_time: "2020-10-13T19:58:31.9Z",
  // },
  // {
  //   id: "bba3f583-7c13-489b-8e95-c751f8211e78",
  //   start_time: "2020-10-11T18:16:33.468Z",
  //   end_time: "2020-10-11T19:58:31.9Z",
  // },
  // {
  //   id: "7c2bbb52-fd08-478d-82b0-c0bc5892eacd",
  //   start_time: "2020-10-09T18:16:33.468Z",
  //   end_time: "2020-10-09T19:58:31.9Z",
  // },
  // {
  //   id: "739aede5-2399-4e80-af1b-a8ec1a63fba1",
  //   start_time: "2020-10-07T18:16:33.468Z",
  //   end_time: "2020-10-07T19:58:31.9Z",
  // },
  // {
  //   id: "ce0ec348-3f54-4252-944a-8e70eba47c7b",
  //   start_time: "2020-10-05T18:16:33.468Z",
  //   end_time: "2020-10-05T19:58:31.9Z",
  // },
  // {
  //   id: "92733e20-0099-485f-a5e4-abe6be0d71f9",
  //   start_time: "2020-10-03T18:16:33.468Z",
  //   end_time: "2020-10-03T19:58:31.9Z",
  // },
  // {
  //   id: "69b34ee6-90ff-4154-8c81-2af6be40e77f",
  //   start_time: "2020-10-01T18:16:33.468Z",
  //   end_time: "2020-10-01T19:58:31.9Z",
  // },
  // {
  //   id: "29ebae2c-a755-4079-9e02-a8a65fb99516",
  //   start_time: "2020-09-29T18:16:33.468Z",
  //   end_time: "2020-09-29T19:58:31.9Z",
  // },
  // {
  //   id: "b4c877f9-2f95-4b92-a556-49f4efe4635Z",
  //   start_time: "2020-09-27T18:16:33.468Z",
  //   end_time: "2020-09-27T19:58:31.9Z",
  // },
  // {
  //   id: "6cd7804e-1417-4fb7-8676-8531da8382c7",
  //   start_time: "2020-09-25T18:16:33.468Z",
  //   end_time: "2020-09-25T19:58:31.9Z",
  // },
  // {
  //   id: "34d306e0-329f-4e4f-802c-fb748312b9af",
  //   start_time: "2020-09-23T18:16:33.468Z",
  //   end_time: "2020-09-23T19:58:31.9Z",
  // },
  // {
  //   id: "95b13e3a-015a-48eb-956d-a9f1b430c503",
  //   start_time: "2020-09-21T18:16:33.468Z",
  //   end_time: "2020-09-21T19:58:31.9Z",
  // },
  // {
  //   id: "b89010ec-de8c-48aa-b6e0-26e8699a8f69",
  //   start_time: "2020-09-19T18:16:33.468Z",
  //   end_time: "2020-09-19T19:58:31.9Z",
  // },

  // {
  //   id: "ed680151-5b74-5950-84c0-98056e7941c6",
  //   start_time: "2020-09-30T23:30:16.859Z",
  //   end_time: "2020-09-30T23:34:08.0Z",
  // },
  // {
  //   id: "a3e9a979-71e9-45ce-8292-762dda94d6bf",
  //   start_time: "2020-09-29T23:30:16.859Z",
  //   end_time: "2020-09-29T23:34:08.0Z",
  // },
  // {
  //   id: "97bc3235-d362-49e9-9a77-240b52bc4a8f",
  //   start_time: "2020-10-02T23:30:16.859Z",
  //   end_time: "2020-10-02T23:34:08.0Z",
  // },
  // {
  //   id: "70cf5a4b-5236-4e7b-80bb-3f5e29b25e24",
  //   start_time: "2020-09-27T23:30:16.859Z",
  //   end_time: "2020-09-27T23:34:08.0Z",
  // },
  // {
  //   id: "28c350fc-737f-4c8b-8b22-601e73f04044",
  //   start_time: "2020-10-04T23:30:16.859Z",
  //   end_time: "2020-10-04T23:34:08.0Z",
  // },
  // {
  //   id: "45fe9fc8-168a-4748-b019-e5b829fa51c2",
  //   start_time: "2020-09-25T23:30:16.859Z",
  //   end_time: "2020-09-25T23:34:08.0Z",
  // },
  // {
  //   id: "26c8d5c0-7ccc-4808-9c9f-53be1485e7d2",
  //   start_time: "2020-10-06T23:30:16.859Z",
  //   end_time: "2020-10-06T23:34:08.0Z",
  // },
  // {
  //   id: "ef654e33-540f-461e-a5c2-976c258a9eaZ",
  //   start_time: "2020-09-23T23:30:16.859Z",
  //   end_time: "2020-09-23T23:34:08.0Z",
  // },
  // {
  //   id: "84897a80-afef-4c46-be2b-f5dcd034e4c1",
  //   start_time: "2020-10-08T23:30:16.859Z",
  //   end_time: "2020-10-08T23:34:08.0Z",
  // },
  // {
  //   id: "df27cabc-dbc5-4f99-951f-8c4e7e5b4741",
  //   start_time: "2020-09-21T23:30:16.859Z",
  //   end_time: "2020-09-21T23:34:08.0Z",
  // },
  // {
  //   id: "d0f7350a-e5e1-4784-9bc1-d91c7c9bf913",
  //   start_time: "2020-10-10T23:30:16.859Z",
  //   end_time: "2020-10-10T23:34:08.0Z",
  // },
  // {
  //   id: "3ff4a3be-d851-4d85-8f05-172ce6a88c5c",
  //   start_time: "2020-09-19T23:30:16.859Z",
  //   end_time: "2020-09-19T23:34:08.0Z",
  // },
  // {
  //   id: "a3867e46-c861-49dd-a032-b39df8829ee1",
  //   start_time: "2020-10-12T23:30:16.859Z",
  //   end_time: "2020-10-12T23:34:08.0Z",
  // },
  // {
  //   id: "b43a7ca7-1fea-413b-89f6-7420a4c3d066",
  //   start_time: "2020-09-17T23:30:16.859Z",
  //   end_time: "2020-09-17T23:34:08.0Z",
  // },
  // {
  //   id: "192cc613-61c3-4ca8-8b00-15846b8c19a7",
  //   start_time: "2020-10-14T23:30:16.859Z",
  //   end_time: "2020-10-14T23:34:08.0Z",
  // },
  // {
  //   id: "6c01d805-3a0e-4347-85ff-f3f80238ba03",
  //   start_time: "2020-09-15T23:30:16.859Z",
  //   end_time: "2020-09-15T23:34:08.0Z",
  // },
  // {
  //   id: "6ea34281-788d-4d09-8e4b-d440cf0bbae3",
  //   start_time: "2020-10-16T23:30:16.859Z",
  //   end_time: "2020-10-16T23:34:08.0Z",
  // },
  // {
  //   id: "8f9a5fa2-7bd4-451e-a0ec-68ea0050d965",
  //   start_time: "2020-09-13T23:30:16.859Z",
  //   end_time: "2020-09-13T23:34:08.0Z",
  // },
  // {
  //   id: "6c5ae0bc-673d-4853-a29e-9ad3e121e5db",
  //   start_time: "2020-10-18T23:30:16.859Z",
  //   end_time: "2020-10-18T23:34:08.0Z",
  // },
  // {
  //   id: "8d2f41f6-d569-44ef-bdcb-1e1b59d8b595",
  //   start_time: "2020-09-11T23:30:16.859Z",
  //   end_time: "2020-09-11T23:34:08.0Z",
  // },
  // {
  //   id: "bb44e0a0-91cc-48db-95a8-f097c8f166cf",
  //   start_time: "2020-10-20T23:30:16.859Z",
  //   end_time: "2020-10-20T23:34:08.0Z",
  // },
  // {
  //   id: "dc201fdf-1f66-4b17-a079-f5a8c41412b2",
  //   start_time: "2020-09-09T23:30:16.859Z",
  //   end_time: "2020-09-09T23:34:08.0Z",
  // },
  // {
  //   id: "aef3fa57-2b59-4769-885f-871797b709c1",
  //   start_time: "2020-10-22T23:30:16.859Z",
  //   end_time: "2020-10-22T23:34:08.0Z",
  // },
  // {
  //   id: "236eea56-d1e8-4dc7-b07e-7c6af2cd8612",
  //   start_time: "2020-09-07T23:30:16.859Z",
  //   end_time: "2020-09-07T23:34:08.0Z",
  // },
  // {
  //   id: "f82754ad-9ac6-4477-b173-5602122e6d85",
  //   start_time: "2020-10-24T23:30:16.859Z",
  //   end_time: "2020-10-24T23:34:08.0Z",
  // },
  // {
  //   id: "426469ea-a77e-46f9-b113-444ee08b7da9",
  //   start_time: "2020-09-05T23:30:16.859Z",
  //   end_time: "2020-09-05T23:34:08.0Z",
  // },
  // {
  //   id: "a4dd547d-a0fb-474e-b2f3-d0511a4a2663",
  //   start_time: "2020-10-26T23:30:16.859Z",
  //   end_time: "2020-10-26T23:34:08.0Z",
  // },
  // {
  //   id: "13411e61-8e07-4d88-a0dd-ee5257612ace",
  //   start_time: "2020-09-03T23:30:16.859Z",
  //   end_time: "2020-09-03T23:34:08.0Z",
  // },
  // {
  //   id: "b20f5785-74ec-4371-b55b-ea8422f3824e",
  //   start_time: "2020-10-28T23:30:16.859Z",
  //   end_time: "2020-10-28T23:34:08.0Z",
  // },
  // {
  //   id: "c1f2fee7-0c37-4fbc-a5d3-908b8b9faa6Z",
  //   start_time: "2020-09-01T23:30:16.859Z",
  //   end_time: "2020-09-01T23:34:08.0Z",
  // },

  // {
  //   id: "f1fd3527-cb7b-5355-ace5-b32a14c015e7",
  //   start_time: "2020-10-01T00:02:42.9Z",
  //   end_time: "2020-10-01T00:15:39.1Z",
  // },
  // {
  //   id: "7145cb14-2d1f-4b62-95cf-da42d0ec36c7",
  //   start_time: "2020-09-30T00:02:42.9Z",
  //   end_time: "2020-09-30T00:15:39.1Z",
  // },
  // {
  //   id: "d0188eef-ded9-43ef-af40-a0e871853a7c",
  //   start_time: "2020-10-03T00:02:42.9Z",
  //   end_time: "2020-10-03T00:15:39.1Z",
  // },
  // {
  //   id: "2d36909e-7846-4e04-af88-e9d9bea3b7e1",
  //   start_time: "2020-09-28T00:02:42.9Z",
  //   end_time: "2020-09-28T00:15:39.1Z",
  // },
  // {
  //   id: "91604ba3-f0a9-409a-8690-4a92af339637",
  //   start_time: "2020-10-05T00:02:42.9Z",
  //   end_time: "2020-10-05T00:15:39.1Z",
  // },
  // {
  //   id: "a6e548ac-690e-442a-90cd-9c9bc577c144",
  //   start_time: "2020-09-26T00:02:42.9Z",
  //   end_time: "2020-09-26T00:15:39.1Z",
  // },
  // {
  //   id: "04585764-d1cb-41bb-a2dc-da111e67df21",
  //   start_time: "2020-10-07T00:02:42.9Z",
  //   end_time: "2020-10-07T00:15:39.1Z",
  // },
  // {
  //   id: "6fb46da7-127d-4769-b03f-8a98bff3fe41",
  //   start_time: "2020-09-24T00:02:42.9Z",
  //   end_time: "2020-09-24T00:15:39.1Z",
  // },
  // {
  //   id: "b19ee032-82a4-4386-856c-3e8ee6eea401",
  //   start_time: "2020-10-09T00:02:42.9Z",
  //   end_time: "2020-10-09T00:15:39.1Z",
  // },
  // {
  //   id: "98052877-68b5-47e6-86d6-0be4f941ab3f",
  //   start_time: "2020-09-22T00:02:42.9Z",
  //   end_time: "2020-09-22T00:15:39.1Z",
  // },
  // {
  //   id: "ec1439db-0766-4a99-bc14-8d3f3608acd1",
  //   start_time: "2020-10-11T00:02:42.9Z",
  //   end_time: "2020-10-11T00:15:39.1Z",
  // },
  // {
  //   id: "c3708b57-13fa-4386-a3d9-5b466d4f960a",
  //   start_time: "2020-09-20T00:02:42.9Z",
  //   end_time: "2020-09-20T00:15:39.1Z",
  // },
  // {
  //   id: "d73a5682-de96-4273-88f2-703762043269",
  //   start_time: "2020-10-13T00:02:42.9Z",
  //   end_time: "2020-10-13T00:15:39.1Z",
  // },
  // {
  //   id: "df32daa0-3d77-44b9-86fa-6651adafd8bd",
  //   start_time: "2020-09-18T00:02:42.9Z",
  //   end_time: "2020-09-18T00:15:39.1Z",
  // },
  // {
  //   id: "14641da4-64df-41a4-bda2-f63058e998ba",
  //   start_time: "2020-10-15T00:02:42.9Z",
  //   end_time: "2020-10-15T00:15:39.1Z",
  // },
  // {
  //   id: "349459d0-66a6-407c-a889-3de3045750bb",
  //   start_time: "2020-09-16T00:02:42.9Z",
  //   end_time: "2020-09-16T00:15:39.1Z",
  // },
  // {
  //   id: "df91317a-6a56-45e4-a380-02a78fe6b53f",
  //   start_time: "2020-10-17T00:02:42.9Z",
  //   end_time: "2020-10-17T00:15:39.1Z",
  // },
  // {
  //   id: "61b0417c-5ef9-47bf-9138-5a40ce788ce2",
  //   start_time: "2020-09-14T00:02:42.9Z",
  //   end_time: "2020-09-14T00:15:39.1Z",
  // },
  // {
  //   id: "9356c7a1-bd76-4412-95c1-b45808b971fc",
  //   start_time: "2020-10-19T00:02:42.9Z",
  //   end_time: "2020-10-19T00:15:39.1Z",
  // },
  // {
  //   id: "a0e09111-516a-46f4-8a91-2c959f5b9d0a",
  //   start_time: "2020-09-12T00:02:42.9Z",
  //   end_time: "2020-09-12T00:15:39.1Z",
  // },
  // {
  //   id: "26537b10-a528-4601-9143-77d1af08bf05",
  //   start_time: "2020-10-21T00:02:42.9Z",
  //   end_time: "2020-10-21T00:15:39.1Z",
  // },
  // {
  //   id: "a8433612-df48-473b-b228-379ae358f046",
  //   start_time: "2020-09-10T00:02:42.9Z",
  //   end_time: "2020-09-10T00:15:39.1Z",
  // },
  // {
  //   id: "416f6ee2-e853-480b-90ba-2f5d2ff6b39a",
  //   start_time: "2020-10-23T00:02:42.9Z",
  //   end_time: "2020-10-23T00:15:39.1Z",
  // },
  // {
  //   id: "e22828a5-56d2-4cd9-a402-a28febbb1d66",
  //   start_time: "2020-09-08T00:02:42.9Z",
  //   end_time: "2020-09-08T00:15:39.1Z",
  // },
  // {
  //   id: "bfe91f8f-8223-45f0-b94c-50806a4fa0fb",
  //   start_time: "2020-10-25T00:02:42.9Z",
  //   end_time: "2020-10-25T00:15:39.1Z",
  // },
  // {
  //   id: "743e9dfc-50e5-45a1-a196-8f7c90b66874",
  //   start_time: "2020-09-06T00:02:42.9Z",
  //   end_time: "2020-09-06T00:15:39.1Z",
  // },
  // {
  //   id: "299ffd61-e841-438b-a3de-efa60e39eb35",
  //   start_time: "2020-10-27T00:02:42.9Z",
  //   end_time: "2020-10-27T00:15:39.1Z",
  // },
  // {
  //   id: "c09201c5-faf7-4799-9cf0-d6a778bee5e4",
  //   start_time: "2020-09-04T00:02:42.9Z",
  //   end_time: "2020-09-04T00:15:39.1Z",
  // },
  // {
  //   id: "342eff3c-2197-44ce-9368-1c781dc46aa4",
  //   start_time: "2020-10-29T00:02:42.9Z",
  //   end_time: "2020-10-29T00:15:39.1Z",
  // },
  // {
  //   id: "8df98cf7-2c7c-4b5b-96f6-7e6396b6c592",
  //   start_time: "2020-09-02T00:02:42.9Z",
  //   end_time: "2020-09-02T00:15:39.1Z",
  // },
  // {
  //   id: "45d0e10e-1fe8-56d3-a3cc-0fd370fc7b8d",
  //   start_time: "2020-10-20T00:02:42.9Z",
  //   end_time: "2020-10-20T00:02:49.9Z",
  // },
];

export async function getTripInfoForRange(start, end) {
  const scopeStart = new Date(localDate(start));
  const scopeEnd = new Date(localDate(end));
  let selectedTrips = {};
  tripInfo.forEach((item) => {
    const tripStart = new Date(item.start_time);
    const tripEnd = new Date(item.end_time);
    if (tripStart > scopeEnd || tripEnd < scopeStart) return;
    const mapId = item.metadata.map_id || "default";
    selectedTrips[item.id] = {
      startTime: item.start_time,
      endTime: item.end_time,
      mapId: mapId,
      mapInfo: mapInfo[mapId]
    };
  });
  return selectedTrips;
}
