import React from "react";
import { useSelector } from "react-redux";

import BarChart from "../../components/BarChart";
import { selectScenariosServiceIsComputing } from "../scenarioResults/scenariosSlice";
import { selectEventStatsFiltered } from "./statsSlice";

export default function EventStatsFilteredChart() {
  const scenariosServiceIsComputing = useSelector(
    selectScenariosServiceIsComputing
  );
  const eventStatsFiltered = useSelector(selectEventStatsFiltered);

  return (
    <div className="mx-auto" style={{ maxWidth: "800px", minHeight: "250px" }}>
      <BarChart
        isLoading={scenariosServiceIsComputing}
        data={eventStatsFiltered}
        title="Events by Category"
        xAxisTitle="Duration (minutes)"
      />
    </div>
  );
}
