import "./QueryBuilder.css";

import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import LoaderButton from "../../components/LoaderButton";
import CriteriaBuilder from "./CriteriaBuilder";
import {
  createQueryCondition,
  selectQueryCriteria,
  selectQueryMinDuration,
  selectQueryMinGap,
  setQueryMinDuration,
  setQueryMinGap,
} from "./queryControlsSlice";
import { selectScenariosServiceIsComputing } from "../scenarioResults/scenariosSlice";
import { selectEventsServiceStatus } from "../driveEvents/eventsDataSlice";
import { computeScenarios } from "../queryEngine/dataClient";

function isPositiveNumber(input) {
  return !isNaN(input) && !isNaN(parseFloat(input)) && input >= 0;
}

export default function QueryBuilder() {
  const history = useHistory();

  const dispatch = useDispatch();
  const queryCriteria = useSelector(selectQueryCriteria);
  const queryMinGap = useSelector(selectQueryMinGap);
  const queryMinDuration = useSelector(selectQueryMinDuration);
  const queryMinGapValid = isPositiveNumber(queryMinGap);
  const queryMinDurationValid = isPositiveNumber(queryMinDuration);
  const eventsServiceStatus = useSelector(selectEventsServiceStatus);
  const scenariosServiceIsComputing = useSelector(
    selectScenariosServiceIsComputing
  );

  function handleQueryBuilderFormSubmit(event = null) {
    if (event != null) event.preventDefault();
    if (!validateForm()) return;

    try {
      const query = {
        criteria: Object.values(queryCriteria),
        minGap: queryMinGap,
        minDuration: queryMinDuration,
      };
      computeScenarios(query);
      history.push("/filtered");
    } catch (e) {
      console.error(e);
    }
  }

  function validateForm() {
    try {
      if (!eventsServiceStatus.ready) return false;

      const queryCriteriaExists =
        !!queryCriteria && Object.keys(queryCriteria).length > 0;
      const queryCriteriaValid =
        queryMinGapValid &&
        queryMinDurationValid &&
        queryCriteriaExists &&
        Object.values(queryCriteria).every((v) => v.valid);
      return queryCriteriaValid;
    } catch (e) {
      return false;
    }
  }

  function onAddConditionClick(event) {
    event.preventDefault();
    event.stopPropagation();
    dispatch(createQueryCondition());
  }

  return (
    <Form name="query" onSubmit={handleQueryBuilderFormSubmit}>
      <Card>
        <Card.Header>
          <Card.Text>Criteria</Card.Text>
        </Card.Header>
        <ListGroup className="list-group-flush">
          <ListGroup.Item>
            <Form.Row>
              <Form.Group
                as={Col}
                className="mb-0"
                controlId="queryMinDuration"
              >
                <Form.Label size="sm">Min Duration</Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  step="any"
                  size="sm"
                  value={queryMinDuration}
                  isInvalid={!queryMinDurationValid}
                  onChange={(event) => {
                    dispatch(setQueryMinDuration(event.target.value));
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Must be a positive number
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} className="mb-0" controlId="queryMinGap">
                <Form.Label size="sm">Min Gap</Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  step="any"
                  size="sm"
                  value={queryMinGap}
                  isInvalid={!queryMinGapValid}
                  onChange={(event) => {
                    dispatch(setQueryMinGap(event.target.value));
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Must be a positive number
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </ListGroup.Item>
          {Object.keys(queryCriteria).map((conditionId) => (
            <ListGroup.Item key={`condition-${conditionId}`}>
              <CriteriaBuilder index={conditionId} />
            </ListGroup.Item>
          ))}
        </ListGroup>

        <Card.Body>
          <Form.Row className="justify-content-between">
            <Form.Group as={Col} xs="auto" className="mb-0">
              <Button
                className="mb-2"
                disabled={!Object.values(queryCriteria).every((v) => v.valid)}
                onClick={onAddConditionClick}
                variant="outline-primary"
              >
                + Add condition
              </Button>
            </Form.Group>

            <Form.Group as={Col} xs="auto" className="mb-0">
              <LoaderButton
                type="submit"
                variant="success"
                isLoading={scenariosServiceIsComputing}
                disabled={!validateForm()}
              >
                Run
              </LoaderButton>
            </Form.Group>
          </Form.Row>
        </Card.Body>
      </Card>
    </Form>
  );
}
