import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/Form";

import {
  selectQueryCategories,
  selectQueryCondition,
  selectQueryEventTypesForColumn,
  selectQueryOperatorsForColumn,
  updateQueryCondition,
  deleteQueryCondition,
  selectQueryConditionOperands,
  selectQueryConditionOperator,
} from "./queryControlsSlice";

function stringNotEmpty(x) {
  const valid = typeof x === "string" && x.length > 0;
  return valid;
}

function arrayNotEmpty(x) {
  const valid = Array.isArray(x) && x.length > 0;
  return valid;
}

export default function CriteriaBuilder(props) {
  const dispatch = useDispatch();
  const index = props.index;

  const queryCondition = useSelector(selectQueryCondition(props.index));
  const operator = useSelector(selectQueryConditionOperator(props.index));
  const [lhs, rhs] = useSelector(selectQueryConditionOperands(props.index));
  const categories = useSelector(selectQueryCategories);
  const operatorsForColumn = useSelector(selectQueryOperatorsForColumn(lhs));
  const eventTypesForColumn = useSelector(selectQueryEventTypesForColumn(lhs));

  const lhsValid = stringNotEmpty(lhs) && operatorsForColumn.length > 0;
  const operatorValid = stringNotEmpty(operator);
  const rhsValid =
    operator === "in" || operator === "not in"
      ? arrayNotEmpty(rhs)
      : stringNotEmpty(rhs);

  function onCriterionUpdate(updatedCriterion) {
    dispatch(updateQueryCondition(updatedCriterion));
  }

  function onDeleteClick(event) {
    event.preventDefault();
    event.stopPropagation();
    dispatch(deleteQueryCondition(props.index));
  }

  useEffect(() => {
    const valid = lhsValid && operatorValid && rhsValid;
    onCriterionUpdate({
      [index]: {
        ...queryCondition,
        ...{ valid },
      },
    });
  }, [lhsValid, operatorValid, rhsValid]);

  function updateCondition(update) {
    const newCriterion = {
      [index]: {
        ...queryCondition,
        ...update,
      },
    };
    onCriterionUpdate(newCriterion);
  }

  return (
    <React.Fragment>
      <Form.Row>
        <Form.Group as={Col} xs="auto" className="mb-2">
          <Button variant="outline-danger" onClick={onDeleteClick} size="sm">
            &times;
          </Button>
        </Form.Group>

        <Form.Group as={Col} className="mb-2">
          <Form.Control
            id={`lhs-${index}`}
            as="select"
            size="sm"
            value={lhs}
            isInvalid={!lhsValid}
            onChange={(e) =>
              updateCondition({ operator: "", operands: [e.target.value, []] })
            }
          >
            <option value="">--</option>
            {categories.map((category) => (
              <option key={`key-${index}-${category}`} value={category}>
                {category}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Select category
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} xs="auto" className="mb-2">
          <Form.Control
            id={`operator-${index}`}
            as="select"
            size="sm"
            disabled={!lhsValid}
            value={operator}
            isInvalid={!operatorValid}
            onChange={(e) => updateCondition({ operator: e.target.value })}
          >
            <option value="">--</option>
            {operatorsForColumn.map((op) => (
              <option key={`op-${index}-${op}`} value={op}>
                {op}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Select operator
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId={`rhs-${index}`} className="mb-0">
          <FormField
            index={index}
            operator={operator}
            stateValue={rhs}
            value={eventTypesForColumn}
            isValid={rhsValid}
            onChange={(value) => updateCondition({ operands: [lhs, value] })}
          />
        </Form.Group>
      </Form.Row>
    </React.Fragment>
  );
}

function FormField(props) {
  switch (props.operator) {
    case "in":
    case "not in":
      return Array.isArray(props.value) ? (
        <>
          <Form.Control
            id={props.controlId}
            as="select"
            multiple
            required
            isInvalid={!props.isValid}
            value={props.stateValue}
            onChange={(e) => {
              props.onChange(
                Array.from(e.target.selectedOptions, (o) => o.value)
              );
            }}
          >
            {props.value.map((tag) => (
              <option value={tag} key={`tag-${props.index}-${tag}`}>
                {tag}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Select 1 or more options
          </Form.Control.Feedback>
        </>
      ) : null;
    default:
      return <Form.Control id={props.controlId} disabled value="" />;
  }
}
