import "./Main.css";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import QueryBuilder from "../features/queryBuilder/QueryBuilder";
import ResultsTable from "../features/scenarioResults/ResultsTable";
import DriveEventsTable from "../features/driveEvents/DriveEventsTable";
import DataProgress from "../features/driveEvents/DataProgress";
import EventStatsFilteredChart from "../features/stats/EventStatsFilteredChart";
import EventStatsAllChart from "../features/stats/EventStatsAllChart";
import {
  initializeEventsService,
  initializeScenariosService,
} from "../features/queryEngine/dataClient";
import { selectDataScope } from "../features/driveEvents/eventsDataSlice";
import { formatDate, localDate } from "../libs/date-utils";

export default function Main() {
  const history = useHistory();
  const routeParams = useParams();
  const dataScope = useSelector(selectDataScope);
  const scopeFromDate = formatDate(localDate(dataScope.from));
  const scopeToDate = formatDate(localDate(dataScope.to));

  useEffect(() => {
    initializeScenariosService();
    initializeEventsService();
  }, []);

  return (
    <React.Fragment>
      <Col xs={12} lg={4} className="py-3 nemo-sidebar">
        <QueryBuilder />
      </Col>
      <Col xs={12} lg={8} className="py-3 mx-auto">
        <Card className="mb-3">
          <Tab.Container
            defaultActiveKey="data-scope"
            activeKey={routeParams.activeTab}
            onSelect={(tab) => history.push(`/${tab}`)}
          >
            <Card.Header>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="all">All Events</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="filtered">Filtered Events</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="data-scope"
                    className="d-flex justify-content-between"
                  >
                    {scopeFromDate} to {scopeToDate} <DataProgress />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Card.Body>
              <Tab.Content>
                <Tab.Pane eventKey="data-scope">
                  <div className="mx-auto" style={{ minHeight: "250px" }}>
                    <DriveEventsTable />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="all">
                  <EventStatsAllChart />
                </Tab.Pane>
                <Tab.Pane eventKey="filtered">
                  <EventStatsFilteredChart />
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Tab.Container>
        </Card>

        <ResultsTable />
      </Col>
    </React.Fragment>
  );
}
