export default function ProgressDonut({
  className,
  successCount = 0,
  totalCount = 0,
  errorCount = 0,
  initialOffset = 25, // percent from 0 counter-clockwise on unit circle
  size = 1,
  ringColor = "rgba(0, 0, 0, 0.1)",
  progressColor = "rgba(10, 132, 255, 0.9)",
  errorColor = "rgba(220, 53, 69, 0.9)",
  strokeWidth = 5,
  style = {},
}) {
  const successPercent =
    totalCount === 0 ? 0 : (successCount / totalCount) * 100;
  const errorPercent = totalCount === 0 ? 0 : (errorCount / totalCount) * 100;
  const successRemaining = 100 - successPercent;
  const errorRemaining = 100 - errorPercent;

  return (
    <svg
      style={style}
      className={`progress-donut${className ? " " + className : ""}`}
      width={`${size}em`}
      height={`${size}em`}
      viewBox="0 0 42 42"
      role="img"
    >
      <circle
        className="donut-hole"
        cx="21"
        cy="21"
        r="15.9154943"
        fill="transparent"
      >
        <title>{`Progress: ${successCount}/${totalCount}`}</title>
      </circle>
      <circle
        className="donut-ring"
        cx="21"
        cy="21"
        r="15.9154943"
        fill="transparent"
        stroke={ringColor}
        strokeWidth={strokeWidth}
      />
      <circle
        className="donut-segment"
        cx="21"
        cy="21"
        r="15.9154943"
        fill="transparent"
        stroke={progressColor}
        strokeWidth={strokeWidth}
        strokeDasharray={`${successPercent} ${successRemaining}`}
        strokeDashoffset={initialOffset}
      >
        <title>{`Progress: ${successCount}/${totalCount}`}</title>
      </circle>
      <circle
        className="donut-segment"
        cx="21"
        cy="21"
        r="15.9154943"
        fill="transparent"
        stroke={errorColor}
        strokeWidth={strokeWidth}
        strokeDasharray={`${errorPercent} ${errorRemaining}`}
        strokeDashoffset={initialOffset + successRemaining}
      >
        <title>{`Error: ${errorCount}/${totalCount}`}</title>
      </circle>
    </svg>
  );
}
