import "./DriveEventsTable.css";
import React from "react";
import { useSelector } from "react-redux";
import Card from "react-bootstrap/esm/Card";
import Table from "react-bootstrap/Table";
import { MdFiberManualRecord } from "react-icons/md";
import { formatInterval, formatDate, formatTime } from "../../libs/date-utils";
import {
  selectDataScopeIsSet,
  selectTripIds,
  selectTripInfoById,
} from "./eventsDataSlice";
import DataScope from "./DataScope";

export const progressMap = {
  START: "secondary",
  FETCHING: "primary",
  DESERIALIZING: "info",
  SAVING: "warning",
  ERROR: "danger",
  READY: "success",
};

export default function DriveEventsTable() {
  const tripIds = useSelector(selectTripIds);
  const dataScopeIsSet = useSelector(selectDataScopeIsSet);

  return (
    <Card className="mb-3">
      <Card.Body>
        <DataScope />
      </Card.Body>
      <div className="table-responsive auro-data-table">
        {!dataScopeIsSet ? (
          <Card.Body>
            <div className="text-center text-muted">
              Choose a date range and click "Set scope" to load data
            </div>
          </Card.Body>
        ) : tripIds.length === 0 ? (
          <Card.Body>
            <div className="text-center text-muted">
              There are no trips available in the date range selected
            </div>
          </Card.Body>
        ) : (
          <Table>
            <TableHead />
            <tbody>
              {tripIds.map((tripId) => (
                <TableRow key={`trip-${tripId}`} tripId={tripId} />
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </Card>
  );
}

function TableHead() {
  return (
    <thead className="thead-secondary">
      <tr>
        <th scope="col">Trip ID</th>
        <th scope="col">Date</th>
        <th scope="col">Duration</th>
        <th scope="col">Status</th>
      </tr>
    </thead>
  );
}

function TableRow(props) {
  const tripInfo = useSelector(selectTripInfoById(props));
  const startTime = new Date(tripInfo.startTime);
  const endTime = new Date(tripInfo.endTime);
  const duration = (endTime - startTime) / 1000;
  const categories = Object.keys(tripInfo.statuses);

  return (
    <React.Fragment>
      <tr id={`drive-${props.tripId}`}>
        <td>{props.tripId}</td>
        <td>
          {formatDate(startTime)} {formatTime(startTime)}
        </td>
        <td>{formatInterval(duration)}</td>
        <td>
          <TripStatusMini
            tripId={props.tripId}
            statuses={tripInfo.statuses}
            categories={categories}
          />
        </td>
      </tr>

      {/* <tr>
        <td className="border-0 p-0" colSpan="5">
          <TripStatusTable tripId={props.tripId}
            statuses={tripInfo.statuses}
            categories={categories} />
        </td>
      </tr> */}
    </React.Fragment>
  );
}

function TripStatusMini(props) {
  return (
    <div className="d-flex justify-content-start">
      {props.categories.map((tableName) => {
        const tableStatus = props.statuses[tableName];
        const progress = progressMap[tableStatus];

        return (
          <div
            key={`${props.tripId}-${tableName}-mini-status`}
            className={`px-1 text-${progress}`}
            title={`${tableName}:${tableStatus}`}
          >
            <MdFiberManualRecord />
          </div>
        );
      })}
    </div>
  );
}

// function TripStatusTable(props) {
//   return (
//     <Accordion.Collapse eventKey={`accordion-${props.tripId}`}>
//       <Table size="sm" className="mb-0">
//         <tbody>
//           {props.categories.map((tableName) => {
//             const tableStatus = props.statuses[tableName];
//             const progress = progressMap[tableStatus];

//             return (
//               <tr key={`${props.tripId}-${tableName}-status`}>
//                 <td>{tableName}</td>
//                 <td>
//                   <strong className={`text-${progress}`}>{tableStatus}</strong>
//                 </td>
//               </tr>
//             );
//           })}
//         </tbody>
//       </Table>
//     </Accordion.Collapse>
//   );
// }
