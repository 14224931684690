export const eventTypeMappings = {
  categories: {
    0: { name: "camera2d_objects", id: 0 },
    1: { name: "ego", id: 1 },
    2: { name: "interaction", id: 2 },
    3: { name: "map_track", id: 3 },
    4: { name: "objects", id: 4 },
  },
  types: {
    camera2d_objects: {
      0: { name: "unclassified", id: 0 },
      1: { name: "bicycle", id: 1 },
      2: { name: "bus", id: 2 },
      3: { name: "car", id: 3 },
      4: { name: "person", id: 4 },
      5: { name: "stop_sign", id: 5 },
      6: { name: "traffic_light", id: 6 },
      7: { name: "trafficcone", id: 7 },
      8: { name: "truck", id: 8 },
    },
    ego: {
      0: { name: "unclassified", id: 0 },
      1: { name: "static", id: 1 },
      2: { name: "accel", id: 2 },
      3: { name: "cruise", id: 3 },
      4: { name: "decel", id: 4 },
      5: { name: "hard_decel", id: 5 },
      6: { name: "hard_accel", id: 6 },
    },
    interaction: {
      0: { name: "unclassified", id: 0 },
      1: { name: "cross:from_right", id: 1 },
      2: { name: "cross:from_left", id: 2 },
      3: { name: "cut_in:from_right", id: 3 },
      4: { name: "cut_in:from_left", id: 4 },
      5: { name: "cut_out:to_left", id: 5 },
      6: { name: "cut_out:to_right", id: 6 },
      7: { name: "follow", id: 7 },
      8: { name: "lead", id: 8 },
      9: { name: "parallel", id: 9 },
      10: { name: "turn_in:from_right", id: 10 },
      11: { name: "turn_out:to_right", id: 11 },
      12: { name: "turn_out:to_left", id: 12 },
      13: { name: "anti_parallel", id: 13 },
    },
    map_track: {
      0: { name: "unclassified", id: 0 },
      1: { name: "junction", id: 1 },
      2: { name: "lanelet", id: 2 },
      3: { name: "crosswalk", id: 3 },
      4: { name: "stopsign", id: 4 },
      5: { name: "speedbump", id: 5 },
    },
    objects: {
      0: { name: "unclassified", id: 0 },
      1: { name: "vehicle", id: 1 },
      2: { name: "unknown", id: 2 },
      3: { name: "pedestrian", id: 3 },
      4: { name: "bicycle", id: 4 },
    },
  },
  operators: {
    camera2d_objects: ["in"],
    ego: ["in"],
    interaction: ["in"],
    map_track: ["in"],
    objects: ["in"],
  },
  aliases: {
    camera2d_objects: { name: "camera2d", prefix: "c2d" },
    ego: { name: "ego", prefix: "ego" },
    interaction: { name: "interaction", prefix: "int" },
    map_track: { name: "map", prefix: "map" },
    objects: { name: "objects", prefix: "obj" },
  }
};
