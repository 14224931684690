import "./Login.css";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/Form";
import LoaderButton from "../../components/LoaderButton";
import Loader from "../../components/Loader";
import { useFormFields } from "../../libs/hooks-lib";
import {
  completeNewPassword,
  selectIsAuthenticating,
} from "./authSlice";

export default function LoginNew() {
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const isAuthenticating = useSelector(selectIsAuthenticating);

  const [fields, handleFieldChange] = useFormFields({
    email: searchParams.get("email") || "",
    tempPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.tempPassword.length > 0 &&
      fields.newPassword.length > 0 &&
      fields.newPassword === fields.confirmPassword
    );
  }

  function handleSubmit(event) {
    event.preventDefault();

    const args = {
      username: fields.email,
      tempPassword: fields.tempPassword,
      newPassword: fields.newPassword,
    };
    dispatch(completeNewPassword(args));
  }

  return (
    <Col md={8} lg={6} className="m-auto py-3">
      {isAuthenticating ? (
        <Loader />
      ) : (
        <Card>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={fields.email}
                  disabled={isAuthenticating}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="tempPassword">
                <Form.Label>Temporary Password</Form.Label>
                <Form.Control
                  type="password"
                  autoComplete="off"
                  value={fields.oldPassword}
                  disabled={isAuthenticating}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <hr />
              <Form.Group size="lg" controlId="newPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  autoComplete="off"
                  value={fields.newPassword}
                  disabled={isAuthenticating}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group size="lg" controlId="confirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  autoComplete="off"
                  value={fields.confirmPassword}
                  disabled={isAuthenticating}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                isLoading={isAuthenticating}
                disabled={!validateForm()}
              >
                Update Password
              </LoaderButton>
            </Form>
          </Card.Body>
        </Card>
      )}
    </Col>
  );
}
