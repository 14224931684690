export function alertInfo(info) {
  alert(info);
}

export function onError(error) {
  let message = String(error);

  if (error == null) {
    message = "an unknown error has occurred";
  }
  // Auth errors
  else if (!(error instanceof Error) && error.message) {
    message = error.message;
  }

  console.error(message, error);
  alert(message);
}
