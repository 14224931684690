import avro from "avsc";
import lf from "lovefield";

export async function checkDataExists(db, tableName) {
  const table = db.getSchema().table(tableName);
  const column = lf.fn.count();
  const rows = await db.select(column).from(table).exec();
  return rows[0][column.getName()] > 0;
}

export function decodeAvroBinary(blob, callbacks) {
  const decoder = avro.createBlobDecoder(blob);
  decoder.on("data", callbacks.onData);
  decoder.on("finish", callbacks.onFinish);
}

export function makeThrottledFn(func, delay) {
  let delayed = false;
  return function throttled() {
    let result;
    if (!delayed) {
      result = func.apply(this, arguments);
      delayed = true;
      setTimeout(function () {
        delayed = false;
      }, delay);
    }
    return result;
  };
}

export function makeDebouncedFn(func, delay) {
  let timeoutId;
  return function debounced() {
    const context = this;
    const args = arguments;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      timeoutId = null;
      func.apply(context, args);
    }, delay);
  };
}
