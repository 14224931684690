import { createSlice } from "@reduxjs/toolkit";

export const annnotationSlice = createSlice({
  name: "annotation",
  initialState: {
    batchName: "",
    taskType: "bounding_box",
    subSamplingCount: 20,
  },

  reducers: {
    setAnnotationBatchName(state, action) {
      state.batchName = action.payload;
    },
    setAnnotationTaskType(state, action) {
      state.taskType = action.payload;
    },
    setAnnotationSubSamplingCount(state, action) {
      state.subSamplingCount = action.payload;
    },
  },
});

export const {
  setAnnotationBatchName,
  setAnnotationTaskType,
  setAnnotationSubSamplingCount,
} = annnotationSlice.actions;

export const selectAnnotationBatchName = ({ annotation }) =>
  annotation.batchName;
export const selectAnnotationTaskType = ({ annotation }) => annotation.taskType;
export const selectAnnotationSubSamplingCount = ({ annotation }) =>
  annotation.subSamplingCount;
export default annnotationSlice.reducer;
