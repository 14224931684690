import { createSlice } from "@reduxjs/toolkit";
import { eventTypeMappings } from "../queryEngine/eventTypeMappings";

export const statsSlice = createSlice({
  name: "stats",
  initialState: {
    requestStatus: {
      eventStatsAllComputing: false,
      eventStatsFilteredComputing: false,
    },
    eventStatsAll: [],
    eventStatsFiltered: [],
  },

  reducers: {
    setEventStatsAll: {
      reducer: (state, action) => {
        state.eventStatsAll = action.payload;
        state.requestStatus.eventStatsAllComputing = false;
      },
      prepare: prepareEventCountsLabels,
    },
    setEventStatsFiltered: {
      reducer: (state, action) => {
        state.eventStatsFiltered = action.payload;
        state.requestStatus.eventStatsFilteredComputing = false;
      },
      prepare: prepareEventCountsLabels,
    },
    setEventStatsAllComputing(state) {
      state.requestStatus.eventStatsAllComputing = true;
    },
    setEventStatsFilteredComputing(state) {
      state.requestStatus.eventStatsFilteredComputing = true;
    },
  },
});

export const {
  setEventStatsAll,
  setEventStatsFiltered,
  setEventStatsAllComputing,
  setEventStatsFilteredComputing,
} = statsSlice.actions;

export function selectEventStatsAll(state) {
  return state.stats.eventStatsAll;
}

export function selectEventStatsFiltered(state) {
  return state.stats.eventStatsFiltered;
}

export const selectRequestStatus = (state) => state.stats.requestStatus;

export function selectEventStatsAllComputing(state) {
  return selectRequestStatus(state).eventStatsAllComputing;
}

export function selectEventStatsFilteredComputing(state) {
  return selectRequestStatus(state).eventStatsFilteredComputing;
}

// name collisions in event types cause the graphs to render in
// undesirable ways, so we prepend a short category prefix to the
// event types to avoid this
function prepareEventCountsLabels(eventTypeCounts) {
  let prefixedEventTypeCounts = {};
  Object.keys(eventTypeCounts).forEach((category) => {
    const categoryPrefix = eventTypeMappings.aliases[category].prefix;
    const countsByType = eventTypeCounts[category];
    let prefixedCounts = {};
    Object.keys(countsByType).forEach((eventType) => {
      const prefixedType = `${categoryPrefix}:${eventType}`;
      prefixedCounts[prefixedType] = countsByType[eventType];
    });
    prefixedEventTypeCounts[category] = prefixedCounts;
  });

  return { payload: prefixedEventTypeCounts };
}

export default statsSlice.reducer;
