import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import {
  selectDataScope,
  setDataScopeFrom,
  setDataScopeTo,
} from "./eventsDataSlice";
import { updateEventsServiceForScope } from "../queryEngine/dataClient";

const timestampRegex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
function isValidDate(ts) {
  return timestampRegex.test(ts) && !isNaN(new Date(ts));
}

export default function DataScope() {
  const dispatch = useDispatch();
  const dataScope = useSelector(selectDataScope);

  function validateForm() {
    try {
      const queryFromTsValid = isValidDate(dataScope.from);
      const queryToTsValid = isValidDate(dataScope.to);
      const fromPrecedesTo = new Date(dataScope.from) < new Date(dataScope.to);
      return queryFromTsValid && queryToTsValid && fromPrecedesTo;
    } catch (e) {
      return false;
    }
  }

  return (
    <div className="d-flex justify-content-between text-secondary">
      <InputGroup size="sm" className="mr-2">
        <InputGroup.Prepend>
          <InputGroup.Text>From</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          type="text"
          value={dataScope.from}
          placeholder="YYYY-MM-DD"
          isInvalid={!isValidDate(dataScope.from)}
          onChange={(event) => {
            dispatch(setDataScopeFrom(event.target.value));
          }}
        />
      </InputGroup>

      <InputGroup size="sm" className="mr-2">
        <InputGroup.Prepend>
          <InputGroup.Text>To</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          type="text"
          value={dataScope.to}
          placeholder="YYYY-MM-DD"
          isInvalid={!isValidDate(dataScope.to)}
          onChange={(event) => {
            dispatch(setDataScopeTo(event.target.value));
          }}
        />
      </InputGroup>

      <Button
        size="sm"
        variant="primary"
        className="text-nowrap"
        disabled={!validateForm()}
        onClick={(event) => {
          event.preventDefault();
          updateEventsServiceForScope();
        }}
      >
        Set scope
      </Button>
    </div>
  );
}
