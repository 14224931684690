import { onError } from "./alert-lib";

export function setLocalStorage(storageKey, storageValue) {
  try {
    window.localStorage.setItem(storageKey, JSON.stringify(storageValue));
    return storageValue;
  } catch (error) {
    onError(error);
  }
}

export function getLocalStorage(storageKey) {
  try {
    const storageValue = window.localStorage.getItem(storageKey);
    try {
      return JSON.parse(storageValue);
    } catch (error) {
      console.warn("error parsing stored value:", error);
      console.warn("returning unparsed value:", storageValue);
      return storageValue;
    }
  } catch (error) {
    onError(error);
  }
}
