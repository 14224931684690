import { Storage } from "aws-amplify";
import store from "../../store";

import {
  setPagedScenarioResults,
  setScenariosPageNum,
  setScenariosSummary,
  setScenariosServiceReady,
  selectScenariosPageSize,
  setScenariosServiceInitializing,
  setScenariosServiceComputing,
  setScenariosServiceError,
  selectScenariosSortBy,
  selectScenariosSortDir,
  selectScenariosPageNum,
  setScenariosServicePending,
} from "../scenarioResults/scenariosSlice";
import {
  selectDataScope,
  selectTrips,
  selectTripTableStatuses,
  setEventsServiceInitializing,
  setTripTableStatus,
  setTripTableStatuses,
  tripTableStatus,
  updateTripsState,
} from "../driveEvents/eventsDataSlice";
import {
  setEventStatsFiltered,
  setEventStatsAll,
  setEventStatsAllComputing,
} from "../stats/statsSlice";
import { getTripInfoForRange } from "./tripInfo";
import { makeDebouncedFn } from "./dataUtils";
import {
  setQueryCategories,
  setQueryEventTypes,
} from "../queryBuilder/queryControlsSlice";

const S3_EVENTS_DATA_PREFIX = "tmp/";

const worker = new Worker(`workers/data-worker.bundle.js?${process.env.REACT_APP_VERSION}`);
worker.addEventListener("message", workerMessageHandler);

export function initializeScenariosService() {
  store.dispatch(setScenariosServiceInitializing());
  postInitScenariosServiceMessage();
}

export function initializeEventsService() {
  const tripInfo = selectTrips(store.getState());
  store.dispatch(setEventsServiceInitializing(tripInfo));
  postInitEventsServiceMessage(tripInfo);
}

export async function updateEventsServiceForScope() {
  const state = store.getState();
  const oldTrips = selectTrips(state);
  const dataScope = selectDataScope(state);
  const tripInfo = await getTripInfoForRange(dataScope.from, dataScope.to);
  const listItems = await Storage.list(S3_EVENTS_DATA_PREFIX);
  const filteredTrips = handleStorageListResponse(listItems, tripInfo);

  // mark out of scope trips for deletion
  const tripsToDelete = Object.keys(oldTrips).filter(
    (tripId) => filteredTrips[tripId] == null
  );

  store.dispatch(updateTripsState(filteredTrips));
  postInitEventsServiceMessage(filteredTrips, tripsToDelete);
}

export function computeScenarios(query) {
  const { criteria, minDuration, minGap } = query;
  store.dispatch(setScenariosServiceComputing());
  postComputeScenariosMessage(criteria, minDuration, minGap);
}

export function jumpToScenarioResultsPage(pageNum) {
  const state = store.getState();
  const pageSize = selectScenariosPageSize(state);
  const sortBy = selectScenariosSortBy(state);
  const sortDir = selectScenariosSortDir(state);
  store.dispatch(setScenariosServicePending());
  postUpdateScenariosPagingMessage(pageSize, pageNum, sortBy, sortDir);
}

export function sortScenarioResults(sortColumn) {
  const state = store.getState();
  const pageNum = selectScenariosPageNum(state);
  const pageSize = selectScenariosPageSize(state);

  let sortBy = selectScenariosSortBy(state);
  let sortDir = selectScenariosSortDir(state);
  if (sortColumn === sortBy) {
    if (sortDir === "asc") {
      sortDir = "desc";
    } else {
      sortBy = null;
      sortDir = null;
    }
  } else {
    sortBy = sortColumn;
    sortDir = "asc";
  }

  store.dispatch(setScenariosServicePending());
  postUpdateScenariosPagingMessage(pageSize, pageNum, sortBy, sortDir);
}

async function fetchDataForTable(payload) {
  const { tripId, tableName, tableKey } = payload;
  const res = await Storage.get(tableKey, { download: true });
  postHandleSerializedDataMessage(tripId, tableName, res.Body);
}

function handleStorageListResponse(listItems, selectedTrips) {
  if (selectedTrips == null) return;

  let tripsInScope = {};
  listItems.forEach((item) => {
    const keyPath = item.key.split("/");
    const tripId = keyPath[1];
    if (!selectedTrips.hasOwnProperty(tripId)) return;
    if (!tripsInScope.hasOwnProperty(tripId)) {
      tripsInScope[tripId] = {
        ...selectedTrips[tripId],
        tables: [],
        storageKeys: {},
        statuses: {},
      };
    }

    const fileName = keyPath[keyPath.length - 1];
    const tableName = fileName.split(".")[0];
    tripsInScope[tripId].tables.push(tableName);
    tripsInScope[tripId].storageKeys[tableName] = item.key;
    tripsInScope[tripId].statuses[tableName] = tripTableStatus.START;
  });

  return tripsInScope;
}

function postInitEventsServiceMessage(trips, toDelete = []) {
  worker.postMessage({
    type: "INIT_EVENTS_SERVICE",
    payload: {
      trips,
      toDelete,
    },
  });
}

function postInitScenariosServiceMessage(pageSize) {
  worker.postMessage({
    type: "INIT_SCENARIOS_SERVICE",
    payload: { pageSize },
  });
}

function postHandleSerializedDataMessage(tripId, tableName, blob) {
  worker.postMessage({
    type: "HANDLE_SERIALIZED_DATA",
    payload: {
      tripId,
      tableName,
      blob,
    },
  });
}

function postComputeScenariosMessage(criteria, minDuration, minGap) {
  worker.postMessage({
    type: "COMPUTE_SCENARIOS",
    payload: {
      criteria,
      minDuration,
      minGap,
    },
  });
}

function postUpdateScenariosPagingMessage(pageSize, pageNum, sortBy, sortDir) {
  worker.postMessage({
    type: "UPDATE_SCENARIOS_PAGING",
    payload: {
      pageSize,
      pageNum,
      sortBy,
      sortDir,
    },
  });
}

const postComputeEventTypeCountsAllMessage = makeDebouncedFn(() => {
  worker.postMessage({
    type: "COMPUTE_EVENT_TYPE_COUNTS_ALL",
  });
}, 500);

const postComputeEventTypeCountsFilteredMessage = makeDebouncedFn(() => {
  worker.postMessage({
    type: "COMPUTE_EVENT_TYPE_COUNTS_FILTERED",
  });
}, 500);

const debouncedPagedResultsHandler = makeDebouncedFn((payload) => {
  store.dispatch(setPagedScenarioResults(payload));
}, 200);

const debouncedComputeScenariosCompleteHandler = makeDebouncedFn(() => {
  const pageSize = selectScenariosPageSize(store.getState());
  postUpdateScenariosPagingMessage(pageSize, 1, "id", "asc");
  postComputeEventTypeCountsFilteredMessage();
}, 200);

function workerMessageHandler(event) {
  const { type, payload } = event.data;

  if (/_ERROR/.test(type)) {
    console.error("client received", event.data);
  } else {
    console.debug("client received", event.data, performance.now());
  }

  switch (type) {
    case "INIT_SCENARIOS_SERVICE_SUCCESS": {
      store.dispatch(setScenariosServiceReady());
      break;
    }

    case "INIT_EVENTS_DB_DONE": {
      const { tripId, tableStatuses } = payload;

      let statuses = {};
      let tripIsReady = true;
      tableStatuses.forEach((item) => {
        if (item.ready) {
          statuses[item.tableName] = tripTableStatus.READY;
          return;
        }

        tripIsReady = false;
        if (item.error) {
          console.error("INIT_EVENTS_DB_ERROR", item);
          statuses[item.tableName] = tripTableStatus.ERROR;
          return;
        }

        statuses[item.tableName] = tripTableStatus.FETCHING;
        fetchDataForTable({
          tripId,
          tableName: item.tableName,
          tableKey: item.tableKey,
        });
      });

      store.dispatch(setTripTableStatuses({ tripId, statuses }));

      if (tripIsReady) {
        store.dispatch(setEventStatsAllComputing());
        postComputeEventTypeCountsAllMessage();
      }
      break;
    }

    case "FETCH_DATA_FOR_TABLE": {
      fetchDataForTable(payload);
      break;
    }

    case "EVENTS_TABLE_ERROR": {
      const { tripId, tableName } = payload;
      store.dispatch(
        setTripTableStatus({
          tripId,
          tableName,
          status: tripTableStatus.ERROR,
        })
      );
      break;
    }

    case "EVENTS_TABLE_SAVING": {
      const { tripId, tableName } = payload;
      store.dispatch(
        setTripTableStatus({
          tripId,
          tableName,
          status: tripTableStatus.SAVING,
        })
      );
      break;
    }

    case "EVENTS_TABLE_READY": {
      const { tripId, tableName } = payload;
      const state = store.getState();
      const statuses = {
        ...selectTripTableStatuses(state, { tripId }),
        [tableName]: tripTableStatus.READY,
      };

      store.dispatch(
        setTripTableStatus({
          tripId,
          tableName,
          status: tripTableStatus.READY,
        })
      );

      const tripIsReady = Object.keys(statuses).every(
        (tableName) => statuses[tableName] === tripTableStatus.READY
      );

      if (tripIsReady) {
        store.dispatch(setEventStatsAllComputing());
        postComputeEventTypeCountsAllMessage();
      }
      break;
    }

    case "COMPUTE_SCENARIOS_BATCH_COMPLETE":
    case "COMPUTE_SCENARIOS_COMPLETE": {
      debouncedComputeScenariosCompleteHandler();
      break;
    }

    case "COMPUTE_SCENARIOS_ERROR": {
      store.dispatch(setScenariosServiceReady());
      store.dispatch(setScenariosServiceError(true));
      break;
    }

    case "UPDATE_PAGED_SCENARIO_RESULTS": {
      debouncedPagedResultsHandler(payload);
      break;
    }

    case "UPDATE_SCENARIOS_SUMMARY": {
      store.dispatch(setScenariosSummary(payload.summary));
      break;
    }

    case "UPDATE_SCENARIOS_PAGING_SUCCESS": {
      store.dispatch(setScenariosPageNum(payload.pageNum));
      break;
    }

    case "UPDATE_SCENARIOS_PAGING_ERROR": {
      store.dispatch(setScenariosServiceError(true));
      break;
    }

    case "COMPUTE_EVENT_TYPE_COUNTS_ALL_SUCCESS": {
      store.dispatch(setQueryCategories(payload.eventTypeCounts));
      store.dispatch(setQueryEventTypes(payload.eventTypeCounts));
      store.dispatch(setEventStatsAll(payload.eventTypeCounts));
      break;
    }

    case "COMPUTE_EVENT_TYPE_COUNTS_FILTERED_SUCCESS": {
      store.dispatch(setEventStatsFiltered(payload.eventTypeCounts));
      break;
    }

    default:
      break;
  }
}
