import "./Login.css";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/Form";
import LoaderButton from "../../components/LoaderButton";
import { useFormFields } from "../../libs/hooks-lib";
import {
  forgotPassword,
  selectIsAuthenticating,
  selectVerificationCodeRequested,
} from "./authSlice";
import { Link, useHistory } from "react-router-dom";

export default function LoginForgot() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticating = useSelector(selectIsAuthenticating);
  const verificationCodeRequested = useSelector(
    selectVerificationCodeRequested
  );
  const [fields, handleFieldChange] = useFormFields({
    email: "",
  });

  useEffect(() => {
    if (fields.email && verificationCodeRequested) {
      history.push(
        `/login/forgot/verify?email=${encodeURIComponent(fields.email)}`
      );
    }
  }, [history, fields.email, verificationCodeRequested]);

  function validateForm() {
    return fields.email.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    dispatch(forgotPassword(fields.email));
  }

  return (
    <Col md={8} lg={6} className="m-auto py-3">
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group size="lg" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                autoFocus
                type="email"
                value={fields.email}
                onChange={handleFieldChange}
                aria-describedby="emailHelpBlock"
              />
              <Form.Text id="emailHelpBlock" muted>
                Submit your email to verify your account and reset your password
              </Form.Text>
            </Form.Group>
            <LoaderButton
              block
              type="submit"
              size="lg"
              isLoading={isAuthenticating}
              disabled={!validateForm()}
            >
              Submit
            </LoaderButton>
          </Form>
          <div className="mt-2">
            <Link to="/login/forgot/verify">
              Already have a verification code?
            </Link>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}
