export function throttle(callback, limit) {
  let wait = false;
  return () => {
    if (!wait) {
      // eslint-disable-next-line prefer-rest-params
      callback(...arguments);
      wait = true;
      setTimeout(() => {
        wait = false;
      }, limit);
    }
  };
}

export const mediaProperties = [
  "error",
  "src",
  "srcObject",
  "currentSrc",
  "crossOrigin",
  "networkState",
  "preload",
  "buffered",
  "readyState",
  "seeking",
  "currentTime",
  "duration",
  "paused",
  "defaultPlaybackRate",
  "playbackRate",
  "played",
  "seekable",
  "ended",
  "autoplay",
  "loop",
  "mediaGroup",
  "controller",
  "controls",
  "volume",
  "muted",
  "defaultMuted",
  "audioTracks",
  "videoTracks",
  "textTracks",
  "width",
  "height",
  "videoWidth",
  "videoHeight",
  "poster",
];
