import { getLocalStorage, setLocalStorage } from "./libs/localstorage-lib";

const NEMO_APP_STORAGE_KEY = "NemoApp";
const DEBUG_MODE_KEY = "DEBUG_MODE";

export default getConfig({
  s3: {
    REGION: process.env.REACT_APP_S3_REGION,
    BUCKET: process.env.REACT_APP_S3_BUCKET,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_API_GATEWAY_REGION,
    URL: process.env.REACT_APP_API_GATEWAY_URL,
  },
  cognito: {
    REGION: process.env.REACT_APP_COGNITO_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  },
  [NEMO_APP_STORAGE_KEY]: {
    [DEBUG_MODE_KEY]: process.env.REACT_APP_DEBUG_MODE,
  },
});

function getConfig(config) {
  // check the search for a "debug" parameter
  const searchParams = new URLSearchParams(window.location.search);
  // if parameter exists, save it to localStorage
  // otherwise, try to retrieve it from localStorage
  const debugModeStorageKey = `${NEMO_APP_STORAGE_KEY}.${DEBUG_MODE_KEY}`;
  const debugMode = searchParams.get("debug")
    ? setLocalStorage(debugModeStorageKey, searchParams.get("debug"))
    : getLocalStorage(debugModeStorageKey);

  // if debugMode is not found, validate and return existing config
  if (!debugMode) return validateConfig(config);

  // otherwise, override the debug mode portion of the config
  return validateConfig({
    ...config,
    [NEMO_APP_STORAGE_KEY]: {
      [DEBUG_MODE_KEY]: Number(debugMode),
    },
  });
}

function validateConfig(config) {
  Object.keys(config).forEach((configCategory) => {
    Object.keys(config[configCategory]).forEach((configVariable) => {
      console.assert(
        config[configCategory][configVariable] != null,
        `config variable not set: ${configCategory}, ${configVariable}`
      );
    });
  });
  return config;
}
