import "./Settings.css";
import React from "react";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import ChangePassword from "../features/auth/ChangePassword";

export default function Settings() {
  return (
    <Col md={8} lg={6} className="m-auto py-3">
      <Card>
        <Card.Body>
          <ChangePassword />
        </Card.Body>
      </Card>
    </Col>
  );
}
