import React, { Component } from "react";
import { makeThrottledFn } from "../features/queryEngine/dataUtils";

import { mediaProperties } from "../utils";

export default class Video extends Component {
  constructor(props) {
    super(props);
    this.state = { src: null };
    this.videoRef = React.createRef();
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.seek = this.seek.bind(this);
    this.forward = this.forward.bind(this);
    this.replay = this.replay.bind(this);
    this.getProperties = this.getProperties.bind(this);
    this.handleLoadStart = this.handleLoadStart.bind(this);
    this.handleCanPlay = this.handleCanPlay.bind(this);
    this.handleCanPlayThrough = this.handleCanPlayThrough.bind(this);
    this.handlePlay = this.handlePlay.bind(this);
    this.handlePlaying = this.handlePlaying.bind(this);
    this.handlePause = this.handlePause.bind(this);
    this.handleEnded = this.handleEnded.bind(this);
    this.handleWaiting = this.handleWaiting.bind(this);
    this.handleSeeking = this.handleSeeking.bind(this);
    this.handleSeeked = this.handleSeeked.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleSuspend = this.handleSuspend.bind(this);
    this.handleAbort = this.handleAbort.bind(this);
    this.handleEmptied = this.handleEmptied.bind(this);
    this.handleStalled = this.handleStalled.bind(this);
    this.handleLoadedMetaData = this.handleLoadedMetaData.bind(this);
    this.handleLoadedData = this.handleLoadedData.bind(this);
    this.handleTimeUpdate = this.handleTimeUpdate.bind(this);
    this.handleRateChange = this.handleRateChange.bind(this);
    this.handleVolumeChange = this.handleVolumeChange.bind(this);
    this.handleDurationChange = this.handleDurationChange.bind(this);
    this.handleProgress = makeThrottledFn(this.handleProgress.bind(this), 250);
  }

  componentDidUpdate(prevProps) {
    if (this.props.forceReload && !prevProps.forceReload) {
      console.debug("COMPONENT DID UPDATE WITH SAME SRC. TRYING TO RELOAD...");
      this.load();
    }
  }

  // get all video properties
  getProperties() {
    if (!this.videoRef.current) {
      return null;
    }

    return mediaProperties.reduce((properties, key) => {
      properties[key] = this.videoRef.current[key];
      return properties;
    }, {});
  }

  // get playback rate
  get playbackRate() {
    return this.videoRef.current.playbackRate;
  }

  // set playback rate
  // speed of video
  set playbackRate(rate) {
    this.videoRef.current.playbackRate = rate;
  }

  get muted() {
    return this.videoRef.current.muted;
  }

  set muted(val) {
    this.videoRef.current.muted = val;
  }

  get volume() {
    return this.videoRef.current.volume;
  }

  set volume(val) {
    if (val > 1) {
      val = 1;
    }
    if (val < 0) {
      val = 0;
    }
    this.videoRef.current.volume = val;
  }

  // video width
  get videoWidth() {
    return this.videoRef.current.videoWidth;
  }

  // video height
  get videoHeight() {
    return this.videoRef.current.videoHeight;
  }

  // play the video
  play() {
    const promise = this.videoRef.current.play();
    if (promise !== undefined) {
      promise.catch(() => {}).then(() => {});
    }
  }

  // pause the video
  pause() {
    const promise = this.videoRef.current.pause();
    if (promise !== undefined) {
      promise.catch(() => {}).then(() => {});
    }
  }

  // Change the video source and re-load the video:
  load() {
    this.videoRef.current.load();
  }

  // Check if your browser can play different types of video:
  canPlayType(...args) {
    this.videoRef.current.canPlayType(...args);
  }

  // toggle play
  togglePlay() {
    if (this.videoRef.current.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  // seek video by time
  seek(time) {
    try {
      this.videoRef.current.currentTime = time;
    } catch (e) {
      // console.log(e, 'Video is not ready.')
    }
  }

  // jump forward x seconds
  forward(seconds) {
    this.seek(this.videoRef.current.currentTime + seconds);
  }

  // jump back x seconds
  replay(seconds) {
    this.forward(-seconds);
  }

  // Fired when the user agent
  // begins looking for media data
  handleLoadStart(...args) {
    console.debug("HANDLE LOAD START", ...args);
  }

  // A handler for events that
  // signal that waiting has ended
  handleCanPlay(...args) {
    console.debug("HANDLE CAN PLAY", ...args);
  }

  // A handler for events that
  // signal that waiting has ended
  handleCanPlayThrough(...args) {
    console.debug("HANDLE CAN PLAY THROUGH", ...args);
  }

  // A handler for events that
  // signal that waiting has ended
  handlePlaying(...args) {
    console.debug("HANDLE PLAYING", ...args);
  }

  handlePlay(...args) {
    console.debug("HANDLE PLAY", ...args);
  }

  handlePause(...args) {
    console.debug("HANDLE PAUSE", ...args);
  }

  // Fired when the duration of
  // the media resource is first known or changed
  handleDurationChange(...args) {
    console.debug("HANDLE DURATION CHANGE", ...args);
  }

  // Fired while the user agent
  // is downloading media data
  handleProgress(...args) {
    console.debug("HANDLE PROGRESS", ...args);
  }

  // Fired when the end of the media resource
  // is reached (currentTime == duration)
  handleEnded(...args) {
    console.debug("HANDLE ENDED", ...args);
  }

  // Fired whenever the media begins waiting
  handleWaiting(...args) {
    console.debug("HANDLE WAITING", ...args);
  }

  // Fired whenever the player
  // is jumping to a new time
  handleSeeking(...args) {
    console.debug("HANDLE SEEKING", ...args);
  }

  // Fired when the player has
  // finished jumping to a new time
  handleSeeked(...args) {
    console.debug("HANDLE SEEKED", ...args);
  }

  // Fires when the browser is
  // intentionally not getting media data
  handleSuspend(...args) {
    console.debug("HANDLE SUSPEND", ...args);
  }

  // Fires when the loading of an audio/video is aborted
  handleAbort(...args) {
    console.debug("HANDLE ABORT", ...args);
  }

  // Fires when the current playlist is empty
  handleEmptied(...args) {
    console.debug("HANDLE EMPTIED", ...args);
  }

  // Fires when the browser is trying to
  // get media data, but data is not available
  handleStalled(...args) {
    console.debug("HANDLE STALLED", ...args);
  }

  // Fires when the browser has loaded
  // meta data for the audio/video
  handleLoadedMetaData(...args) {
    console.debug("HANDLE LOADED METADATA", ...args);
  }

  // Fires when the browser has loaded
  // the current frame of the audio/video
  handleLoadedData(...args) {
    console.debug("HANDLE LOADED DATA", ...args);
  }

  // Fires when the current
  // playback position has changed
  handleTimeUpdate(...args) {
    console.debug("HANDLE TIME UPDATE", ...args);
  }

  // Fires when the playing speed of the audio/video is changed
  handleRateChange(...args) {
    console.debug("HANDLE RATE CHANGE", ...args);
  }

  handleVolumeChange(...args) {
    console.debug("HANDLE VOLUME CHANGE", ...args);
  }

  handleError(...args) {
    console.debug("HANDLE ERROR", ...args);
  }

  handleResize(...args) {
    console.debug("HANDLE RESIZE", ...args);
  }

  render() {
    return (
      <video
        ref={this.videoRef}
        id={this.props.id}
        src={this.props.src}
        autoPlay={this.props.autoPlay}
        controls={this.props.controls}
        crossOrigin={this.props.crossOrigin}
        loop={this.props.loop}
        muted={this.props.muted}
        playsInline={this.props.playsInline}
        poster={this.props.poster}
        preload={this.props.preload}
        // onLoadStart={this.handleLoadStart}
        // onWaiting={this.handleWaiting}
        // onCanPlay={this.handleCanPlay}
        // onCanPlayThrough={this.handleCanPlayThrough}
        // onPlaying={this.handlePlaying}
        // onEnded={this.handleEnded}
        // onSeeking={this.handleSeeking}
        // onSeeked={this.handleSeeked}
        // onPlay={this.handlePlay}
        // onPause={this.handlePause}
        // onProgress={this.handleProgress}
        // onDurationChange={this.handleDurationChange}
        // onError={this.handleError}
        // onSuspend={this.handleSuspend}
        // onAbort={this.handleAbort}
        // onEmptied={this.handleEmptied}
        // onStalled={this.handleStalled}
        // onLoadedMetadata={this.handleLoadedMetaData}
        // onLoadedData={this.handleLoadedData}
        // onTimeUpdate={this.handleTimeUpdate}
        // onRateChange={this.handleRateChange}
        // onVolumeChange={this.handleVolumeChange}
        tabIndex="-1"
      >
        <p>Your browser does not support embedded video</p>
      </video>
    );
  }
}
